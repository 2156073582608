import './assets/style/styles.scss'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useMapStoreData } from '@/stores/data'
import {useScenariosStore} from '@/stores/scenarios-store';
import {useAnalyticsStore} from '@/stores/analytics-store';
import App from './App.vue'
import router from './router'
// @ts-ignore
import { createI18n,Plugin } from 'vue-i18n'

type CommonMessages = {
  [key: string]: string;
};

type LanguageMessages = {
  [key: string]: string;
};

interface Message {
  [key: string]: string;
}

interface Messages {
  [key: string]: Message;
}



// Service Worker Unregistration Script
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
      registration.unregister();
    }
  });

  const { pathname } = new URL('./service-worker.js', import.meta.url);
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(pathname).then(registration => {
        console.log('Service Worker registered: ', registration);
    }).catch(registrationError => {
        console.log('Service Worker registration failed: ', registrationError);
    });
  });
}
console.log('window.onerror fef');
window.onerror = function(message, source, lineno, colno, error){
  console.log('window.onerror')
  useAnalyticsStore().report_js_error(message.toString(), source, lineno, colno, error, null)
};

/*
Vue.config.errorHandler = (error, vm, info) => {
  console.log('vue error',error, vm, info );
};
*/

const app = createApp(App);

app.use(createPinia())

const mapStore = useMapStoreData();
const url = new URL(window.location.href);

let destination :string | null = null;
let startpoint :string | null = null;
let destinationSet: boolean;


const params = new URLSearchParams(url.search);
destination = params.get('destination');
startpoint = params.get('startpoint');
destinationSet = false;
let i18n: Plugin<[]> | null = null;
let wakeLock: any = null;

useAnalyticsStore().setupAnalytics();
useAnalyticsStore().setStatusData('href', window.location.href);

//save destination to localStorage
if (destination) {  
  localStorage.setItem('destination', destination);
  destinationSet = true;
}else if(localStorage.getItem('destination') !== null) {
  destinationSet = true;
  destination = localStorage.getItem('destination');
}else {
  alert("no destination set");
}

if (startpoint) {
  localStorage.removeItem('startpoint');
  localStorage.setItem('startpoint', startpoint);
//}else if(localStorage.getItem('startpoint') !== null) {
//  startpoint = localStorage.getItem('startpoint');
//}
}else {
  startpoint = "0";
  //localStorage.setItem('startpoint', startpoint);
}

console.log("startpoint is ",startpoint);
useAnalyticsStore().setStatusData('startpoint', startpoint);

if (destinationSet) {
  setDestinationData();
  requestWakeLock();
}


async function requestWakeLock() {
  try {
    wakeLock = await (navigator as any).wakeLock.request('screen');
  } catch (err: any) {
    console.error(`${err.name}, ${err.message}`);
  }
}

function setDestinationData() {
  mapStore.loadJSONFiles(destination,startpoint).then(() => {
      // Code to execute after the action completes successfully
      if(mapStore.languages && mapStore.common) {

        const messages: LanguageMessages = mapStore.languages;
        const options = useMapStoreData().options;
        const common: CommonMessages = mapStore.common;

        let resultMessages: Messages = {};
        let languages = JSON.parse(mapStore.options.languages);
        for(let lang in languages) {
          let currentLang:string = languages[lang];
          let poiTexts:any = messages[currentLang];
          let commonMessages:any = common[currentLang];
          
          resultMessages[currentLang] = { ...poiTexts, ...commonMessages };
        }
        
        //set i18n
        if(mapStore.options !== null) {
          i18n = createI18n({
            legacy: false,  // set to false to use composable mode
            locale: languages[0], // set locale
            fallbackLocale: languages[0], // set fallback locale 45r 
            messages: resultMessages,
            fallbackWarn: false,
            missing: (key:any) => {
              //console.warn(`Missing translation for ${key}`);
              return ''; // Return empty string if key is missing
            }
          });

          if(options.options.hasOwnProperty('has_significance')) {
            if(options.options.has_significance == "true") {
              useScenariosStore().dropdownSortingOptions.push({key:'Favorites', localization:'sortingdropdown.favorited'});
                useScenariosStore().defaultSorting = {key:'Favorites', localization:'sortingdropdown.favorited'};
            }
          }
          useScenariosStore().populateSortedMarkers(useScenariosStore().defaultSorting.key);
        }
        app.use(i18n);

      }
      app.use(router);
      app.mount('#app')
    })
    .catch((error) => {
      // Code to handle any errors that occurred during the action
      console.error('Failed to set publicUrl:', error);
    });
}

export function getI18n() {
  return i18n;
}
