import { defineStore } from 'pinia';
import { useCategoriesStore } from '@/stores/categories-store'
import { useMapStoreData } from '@/stores/data'
import { toPng } from 'html-to-image'
import timeIcon from '@/assets/time-icon.svg'
import idb from '@/utils/idb';

export const useMarkersStore = defineStore('markersStore',{
    state: () => ({
        markersWithImageCreated: false as boolean, 
        markersWithCategoryCreated: false as boolean,
        eventMarkersCreated: false as boolean,
        eventMarkers: new Map() as Map<string, any>,
        eventMarkersClose: new Map() as Map<string, string>,
        eventMarkersCloseCreated: false as boolean,
        closeMarkersCreated: false as boolean,
        closeMarkersWithEventCreated: false as boolean,
        favoriteMarkersCreated: false as boolean,
        functionalMarkersLoaded: false as boolean,
        allMarkersLoaded: false as boolean,
        categoryMarkersLook: new Map() as Map<string, string>,
        categoryMarkersEventLook: new Map() as Map<string, string>,
        categoryMarkersEventCreated: false as boolean,
        markerThumbnails: new Map() as Map<string, any>,
        markerFavorites: new Map() as Map<string, any>,
        closeMarkers: new Map() as Map<string, any>,
        closeMarkersWithEvent: new Map() as Map<string, any>,
        countCategoriesMarkers: 0 as number,
        imagesLoaded: 0 as number,
        markerFavoritesCount: 0 as number,
        closeMarkersCount: 0 as number,
        closeMarkersWithEventCount: 0 as number,
        markerDevicePixelRation: 1 as number,
        mapSeen: false as boolean,
        //obligatoryMarkersCreated: 0 as number,
    }),

    actions: {
        createMarkers() {
            idb.init();
            this.markerDevicePixelRation = window.devicePixelRatio;
            this.createCategoriesMarkers();           
            this.createFavoriteMarkers();
            this.createCloseMarkers();
            this.createEventMarkers();
            this.createEventCloseMarkers();
            this.createCategoriesEventMarkers();
            this.createCloseEventCategoriesMarkers();
        },
        checkMarkerCreationStatus() {
          if(this.markersWithCategoryCreated && this.favoriteMarkersCreated && this.closeMarkersCreated && this.eventMarkersCreated && this.eventMarkersCloseCreated && this.categoryMarkersEventCreated){
            console.log('spura kereirani obvezni markerji');
            this.functionalMarkersLoaded = true;
            //this.createImageMarkers();
          }
        },
        createCategoriesMarkers() {

            let _this = this;
            let prevKey = ''; 
            let color:any = '';
            let promises = [];
            let categoryHierarchy = useCategoriesStore().categoryHierarchy;
            let poiCategories = useCategoriesStore().poiCategories;
            
            for (let [mainCategoryId, value] of categoryHierarchy.entries()) {
                if(mainCategoryId != prevKey) {
                  prevKey = mainCategoryId;
                  if (poiCategories && poiCategories.get(mainCategoryId)) {
                      let category:any = poiCategories.get(mainCategoryId);
                      if (category && category.hasOwnProperty('cssColor')) {
                          color = category.cssColor;
                      }
                  }
                }
      
                this.countCategoriesMarkers = 0;

                for (let [key, value] of poiCategories.entries()) {
                  
                    let promise = new Promise((resolve, reject) => {
                      
                    let colorStart = getComputedStyle(document.body).getPropertyValue(color+'-start');
                    let colorEnd = getComputedStyle(document.body).getPropertyValue(color+'-stop');
                          
                    let categoryIconName = mainCategoryId + '-' + key;
                    let markerKey =  'marker-categories-'+ window.devicePixelRatio +'-'+ encodeURIComponent(value.icon)+'-'+ colorStart + colorEnd; 
                    let saved = idb.getData(markerKey);
 
                    saved.then((imgdata) => {
                    if(imgdata == null) {


                    let node = document.createElement('div');
    
                    node.innerHTML = `
                    <svg width="88" height="114" viewBox="0 0 30 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_i_2258_58156)">
                    <path d="M3.33013 24.5019C-1.48835 18.516 -1.02967 9.84783 4.39343 4.40735C10.2512 -1.46919 19.7487 -1.46908 25.6064 4.40746C31.0295 9.84788 31.4884 18.516 26.6702 24.502L15.7797 38.0322C15.3794 38.5295 14.622 38.5295 14.2217 38.0323L3.33013 24.5019Z" fill="url(#paint0_linear_2258_58156)"/>
                    </g>
                    <image x="5" y="5" width="20" height="20" xlink:href="${value.icon}" />
                    <defs>
                    <filter id="filter0_i_2258_58156" x="0" y="0" width="30" height="38.4062" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2258_58156"/>
                    </filter>
                    <linearGradient id="paint0_linear_2258_58156" x1="15" y1="0" x2="15" y2="39" gradientUnits="userSpaceOnUse">
                    <stop stop-color="${colorStart}"/>
                    <stop offset="1" stop-color="${colorEnd}"/>
                    </linearGradient>
                    </defs>
                    </svg>
                    `;
                    //console.log("before toPng");
                    try {
                      toPng(node,{ width: 88, height: 114 })
                      .then(function (dataUrl) {
                        
                        _this.categoryMarkersLook.set(categoryIconName, dataUrl);
                        idb.saveData(dataUrl, markerKey);
                          _this.countCategoriesMarkers++;
                          resolve(true);
                      })
                      .catch(function (error) {
                        //reject(error);
                        console.log("node error",node);
                        resolve(true);
                        console.error('oops, something went wrong! ', error);
                        console.log("something wrong on marker id:",key )
                      });

                  } catch (error) {
                    resolve(true);
                      console.error('oops, something went wrong!', error);
                  }
                }else{
                  _this.categoryMarkersLook.set(categoryIconName, imgdata);
                  _this.countCategoriesMarkers++;
                  resolve(true);           
                }
              })
                });
 
                promises.push(promise);
                }
               
              promises.push(promises);
            }

            Promise.all(promises)
            .then(() => {
              console.log("its done");
              //console.log("_this.categoryMarkersLook: ",_this.categoryMarkersLook);
                _this.markersWithCategoryCreated = true;
                _this.checkMarkerCreationStatus();

            })
            .catch(error => {
                console.error('An error occurred:', error);
            });
          },
          createCategoriesEventMarkers() {

            let _this = this;
            let prevKey = ''; 
            let color:any = '';
            let promises = [];
            let categoryHierarchy = useCategoriesStore().categoryHierarchy;
            let poiCategories = useCategoriesStore().poiCategories;
            
            for (let [mainCategoryId, value] of categoryHierarchy.entries()) {
                if(mainCategoryId != prevKey) {
                  prevKey = mainCategoryId;
                  if (poiCategories && poiCategories.get(mainCategoryId)) {
                      let category:any = poiCategories.get(mainCategoryId);
                      if (category && category.hasOwnProperty('cssColor')) {
                          color = category.cssColor;
                      }
                  }
                }
      

                for (let [key, value] of poiCategories.entries()) {
                  
                    let promise = new Promise((resolve, reject) => {
                      
                    let colorStart = getComputedStyle(document.body).getPropertyValue(color+'-start');
                    let colorEnd = getComputedStyle(document.body).getPropertyValue(color+'-stop');
                          
                    let categoryIconName = mainCategoryId + '-' + key + '-event';
                    let markerKey =  'marker-categories-event'+ window.devicePixelRatio +'-'+ encodeURIComponent(value.icon)+'-'+ colorStart + colorEnd; 
                    let saved = idb.getData(markerKey);
 
                    saved.then((imgdata) => {
                    if(imgdata == null) {


                    let node = document.createElement('div');
    
                    node.innerHTML = `
                      <svg width="88" height="114" viewBox="0 0 30 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_i_6497_41946)">
                        <path d="M3.33013 24.5019C-1.48835 18.516 -1.02967 9.84783 4.39343 4.40735C10.2512 -1.46919 19.7487 -1.46908 25.6064 4.40746C31.0295 9.84788 31.4884 18.516 26.6702 24.502L15.7797 38.0322C15.3794 38.5295 14.622 38.5295 14.2217 38.0323L3.33013 24.5019Z" fill="url(#paint0_linear_6497_41946)"/>
                        </g>
                        <image x="5" y="5" width="20" height="20" xlink:href="${value.icon}" />
                        <rect x="15" y="24" width="12" height="12" fill="white"/>
                        <path d="M21.0005 21.002C19.2204 21.002 17.4802 21.5298 16.0001 22.5188C14.52 23.5078 13.3664 24.9135 12.6851 26.5581C12.0039 28.2027 11.8257 30.0124 12.1729 31.7583C12.5202 33.5043 13.3774 35.108 14.6362 36.3667C15.8949 37.6255 17.4987 38.4827 19.2446 38.83C20.9905 39.1773 22.8002 38.999 24.4448 38.3178C26.0895 37.6366 27.4951 36.483 28.4841 35.0028C29.4731 33.5227 30.001 31.7826 30.001 30.0024C30.001 28.8205 29.7682 27.6501 29.3159 26.5581C28.8635 25.4661 28.2006 24.4739 27.3648 23.6381C26.529 22.8024 25.5368 22.1394 24.4448 21.6871C23.3528 21.2348 22.1825 21.002 21.0005 21.002ZM24.1147 31.8025C23.9959 32.0084 23.8006 32.159 23.5713 32.2214C23.3419 32.2837 23.0973 32.2529 22.8906 32.1356L20.5505 30.7855L20.4695 30.7045L20.3255 30.5875C20.2903 30.5429 20.2601 30.4946 20.2355 30.4435C20.2061 30.3951 20.182 30.3439 20.1634 30.2905C20.139 30.2332 20.1238 30.1725 20.1184 30.1104L20.1004 30.0024V25.5022C20.1004 25.2635 20.1953 25.0346 20.3641 24.8658C20.5329 24.697 20.7618 24.6021 21.0005 24.6021C21.2392 24.6021 21.4681 24.697 21.6369 24.8658C21.8057 25.0346 21.9005 25.2635 21.9005 25.5022V29.4804L23.7906 30.5695C23.893 30.629 23.9827 30.7082 24.0544 30.8025C24.1261 30.8967 24.1785 31.0042 24.2086 31.1187C24.2387 31.2333 24.2459 31.3527 24.2298 31.47C24.2137 31.5873 24.1745 31.7003 24.1147 31.8025Z" fill="url(#paint1_linear_6497_41946)"/>
                        <defs>
                        <filter id="filter0_i_6497_41946" x="0" y="0" width="30" height="38.4043" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset/>
                        <feGaussianBlur stdDeviation="2.5"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6497_41946"/>
                        </filter>
                        <linearGradient id="paint0_linear_6497_41946" x1="15" y1="0" x2="15" y2="39" gradientUnits="userSpaceOnUse">
                        <stop stop-color="${colorStart}"/>
                        <stop offset="1" stop-color="${colorEnd}"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_6497_41946" x1="21.0005" y1="21.002" x2="21.0005" y2="39.0029" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D6779D"/>
                        <stop offset="1" stop-color="#BD567B"/>
                        </linearGradient>
                        </defs>
                      </svg>
                    `
                    //console.log("before toPng");
                    try {
                      toPng(node,{ width: 88, height: 114 })
                      .then(function (dataUrl) {
                        
                        _this.categoryMarkersEventLook.set(categoryIconName, dataUrl);
                        idb.saveData(dataUrl, markerKey);
                          resolve(true);
                      })
                      .catch(function (error) {
                        //reject(error);
                        console.log("node error",node);
                        resolve(true);
                        console.error('oops, something went wrong! ', error);
                        console.log("something wrong on marker id:",key )
                      });

                  } catch (error) {
                    resolve(true);
                      console.error('oops, something went wrong!', error);
                  }
                }else{
                  _this.categoryMarkersEventLook.set(categoryIconName, imgdata);
                  resolve(true);           
                }
              })
                });
 
                promises.push(promise);
                }
               
              promises.push(promises);
            }

            Promise.all(promises)
            .then(() => {
              console.log("its done event markers categories");
              //console.log("_this.categoryMarkersLook: ",_this.categoryMarkersLook);
                _this.categoryMarkersEventCreated = true;
                _this.checkMarkerCreationStatus();

            })
            .catch(error => {
                console.error('An error occurred:', error);
            });
          },
          createImageMarkers() {
            const appStore = useMapStoreData();
            let _this = this;
            let markers = useMapStoreData().markers;
            let poiCategories = useCategoriesStore().poiCategories;
            let destinationId = useMapStoreData().destinationId;

            if(markers !== null) {
              //loop trough markers
              let markersLoop:any = markers;
              let promises:any = [];

              Object.keys(markersLoop).forEach(function(key, idx,array) {
                let markerElement = markersLoop[key];
                let markerPropreties = markerElement.properties;
                let basic:any = null;
                let categoryPropreties = poiCategories.get(markerPropreties.categories[0]);




                  let imageLoadedPromise = new Promise((imageResolve, imageReject) =>{
                    if(markerPropreties.presentation !== null) {
                      if (markerPropreties && markerPropreties.presentation.hasOwnProperty('basic')) {
                        basic = markerPropreties.presentation.basic;
                      } else {
                        imageResolve('The basicProperty does not exist.');
                      }
                    }else {
                      imageResolve('The basicProperty does not exist.');
                    }

                        
                    if (basic != null && basic.content.hasOwnProperty('gallery') && basic.content.gallery.files.length > 0) {

                      let cssColor = categoryPropreties?.cssColor;
                      let colorStart = getComputedStyle(document.body).getPropertyValue(cssColor+'-start');
                      let colorEnd = getComputedStyle(document.body).getPropertyValue(cssColor+'-stop');
                            
                      let markerKey =  'marker-image-'+ window.devicePixelRatio +'-' + basic.content.gallery.files[0].hash+'-'+ colorStart + colorEnd; 
                      let saved = idb.getData(markerKey);
                      //console.log('uscemo o'+ markerKey);
                      saved.then((imgdata) => {
                         if(imgdata == null) {
                          

                          //save markerElement id as key and first image from gallery as value
                          let image = basic.content.gallery.files[0];
                          let publicUrl:string = appStore.publicUrl;
                          
                            if(destinationId === '21') {
                              publicUrl = 'https://simpl.guide/temp/miza2/'
                            }

                            let imageUrl = publicUrl + 'repo/'+ destinationId +'/' + image.hash + '-icon' + '.jpg'; 
                            
                            let img = document.createElement('img');
                            img.src = imageUrl;
                            //document.querySelector('.checkImages')?.appendChild(img);



                              img.onload = function() {
                                let node = document.createElement('div');

                                node.innerHTML = `<svg width="88" height="114" viewBox="0 0 44 57" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g filter="url(#filter0_i_2204_40740)">
                                <path d="M4.86711 35.8105C-2.17528 27.0619 -1.5049 14.393 6.42117 6.44152C14.9825 -2.14728 28.8634 -2.14712 37.4248 6.44167C45.3508 14.393 46.0215 27.0618 38.9796 35.8106L22.7031 56.0322C22.3028 56.5295 21.5454 56.5295 21.1451 56.0323L4.86711 35.8105Z" fill="url(#paint0_linear_2204_40740)"/>
                                </g>
                                <circle cx="22" cy="22" r="20" fill="url(#pattern0)"/>
                                <circle cx="22" cy="22" r="20" fill="url(#pattern1)"/>
                                <defs>
                                <filter id="filter0_i_2204_40740" x="0" y="0" width="43.8457" height="56.4062" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset/>
                                <feGaussianBlur stdDeviation="2.5"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2204_40740"/>
                                </filter>
                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                <use xlink:href="#image0_2204_40740" transform="scale(0.015625)"/>
                                </pattern>
                                <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                                <use xlink:href="#image1_2204_40740" transform="translate(-0.249665) scale(0.00133869)"/>
                                </pattern>
                                <linearGradient id="paint0_linear_2204_40740" x1="21.9231" y1="0" x2="21.9231" y2="57" gradientUnits="userSpaceOnUse">
                                <stop stop-color="${colorStart}"/>
                                <stop offset="1" stop-color="${colorEnd}"/>

                                </linearGradient>
                                <image id="image0_2204_40740" width="64" height="64" xlink:href="${imageUrl}" />
                                </defs>
                                </svg>
                                `
                                
                                toPng(node,{ width: 88, height: 114 })
                                .then(function (dataUrl) {
                                  _this.markerThumbnails.set(markerPropreties.id, dataUrl);
                                  //console.log('dataUrl', dataUrl)
                                  _this.imagesLoaded++;
                                  idb.saveData(dataUrl, markerKey);
                                  imageResolve(true); 
                                })
                                .catch(function (error) {
                                  //reject(error);
                                  imageReject(error);
                                  _this.imagesLoaded++;
                                  console.error('oops, something went wrong! ', error);
                                  console.log("something wrong on marker id:",markerPropreties.id )
                                });
                                
                              }
                              img.onerror = function() {
                                imageResolve('An error occurred while trying to load the image.');
                                console.log('An error occurred while trying to load the image. on marker: ' , markerPropreties.id);
                              }  



                         }
                         else {

                          _this.markerThumbnails.set(markerPropreties.id, imgdata);
                          imageResolve(true); 
                         }

                      })
                          
                      
                    }else {
                      _this.imagesLoaded++;
                      imageResolve('The basicProperty does not exist.');
                    }
                  });
                  promises.push(imageLoadedPromise);



              });

              Promise.all(promises)
                .then(() => {
                  console.log("its done MARKERS WITH IMAGES");
                    _this.markersWithImageCreated = true;
                    //_this.checkMarkerCreationStatus();
                    console.log('spura kereirani image  markerji');
                })
                .catch(error => {
                    console.error('An ERROR occurred:', error);
                });
            }
          },
          createFavoriteMarkers() {
            let _this = this;
            let prevKey = ''; 
            let color:any = '';
            let promises = [];
            let categoryHierarchy = new Map(useCategoriesStore().categoryHierarchy);
            let poiCategories = useCategoriesStore().poiCategories;
            
            categoryHierarchy.set('favorited-event',['1']);
            for(let [key,value] of categoryHierarchy.entries()) { //todo sejvaj

              let category:any = poiCategories.get(key);
              
              if (category && category.hasOwnProperty('cssColor')) {
                color = category.cssColor;
                this.markerFavoritesCount = 0;
              }
              

                    


              let promise = new Promise((resolve, reject) => {
                let colorStart = '';
                let colorEnd = '';
                
                if(key==='favorited-event') {
                  colorStart  = '#D6779D';
                  colorEnd = '#BD567B';
                } else {
                  colorStart = getComputedStyle(document.body).getPropertyValue(color+'-start');
                  colorEnd = getComputedStyle(document.body).getPropertyValue(color+'-stop');
                }
                      
                let markerKey =  'marker-favorite-'+ window.devicePixelRatio +'-'+ colorStart + colorEnd; 
                let favoritesIconName = 'favorites-' + key;

                let saved = idb.getData(markerKey);
                 
                saved.then((imgdata) => {
                if(imgdata == null) {


                let node = document.createElement('div');
                node.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="100" height="120" fill="none" viewBox="0 0 49.706 60">
                <path fill="url(#a)" d="M7.28 42.426c-9.706-9.705-9.706-25.441 0-35.147 9.705-9.705 25.44-9.705 35.146 0 9.706 9.706 9.706 25.442 0 35.147L24.853 60z" style="fill:url(#a)"/>
                <path fill="#fff" d="M33.379 12.938c.724 0 1.371.457 1.371 1.18V25.62c0 .457-.305.876-.838 1.067-1.638.647-3.009.876-4.227.876-2.819 0-4.761-1.181-7.58-1.181-1.37 0-3.008.305-5.027 1.18v3.961a.902.902 0 0 1-.914.915c-.533 0-.914-.381-.914-.915V13.852c0-.496.38-.914.914-.914.495 0 .914.418.914.914v.342c2.247-.914 3.885-1.218 5.218-1.218 2.628 0 3.96 1.218 6.246 1.218 1.066 0 2.361-.266 4.075-1.066.267-.114.495-.19.762-.19zm-.457 12.187v-10.13c-1.638.685-3.047 1.028-4.38 1.028-1.41 0-2.514-.381-3.466-.686-.914-.267-1.714-.533-2.78-.533-1.257 0-2.742.343-4.532 1.066l-.686.305v9.407c1.752-.685 3.428-1.028 5.028-1.028 1.637 0 3.008.343 4.227.647 1.143.267 2.17.533 3.352.533.99 0 2.056-.19 3.237-.609z"/>
                <defs><linearGradient id="a" x1="34.853" x2="34.853" y1="0" y2="60" gradientTransform="translate(-10)" gradientUnits="userSpaceOnUse">
                <stop stop-color="${colorStart}"/><stop offset="1" stop-color="${colorEnd}"/></linearGradient></defs></svg>
                `;
   
                try {
           
                  toPng(node,{ width: 100, height: 120 })
                  .then(function (dataUrl) {
                    
                    _this.markerFavorites.set(favoritesIconName, dataUrl);
                    idb.saveData(dataUrl, markerKey);
                      _this.markerFavoritesCount++;
                      resolve(true);
                  })
                  .catch(function (error) {
                    //reject(error);
                    console.log("node error",node);
                    resolve(true);
                    console.error('oops, something went wrong! ', error);
                    console.log("something wrong on marker id:",key )
                  });

              } catch (error) {
                resolve(true);
                console.error('oops, something went wrong!', error);
              }

            }else{

              _this.markerFavorites.set(favoritesIconName, imgdata);
              _this.markerFavoritesCount++;
              resolve(true);
            }
            
            });

           
          });
           promises.push(promise);
        }
            
          

            Promise.all(promises)
            .then(() => {
              console.log("favorite markers created its done");
                _this.favoriteMarkersCreated = true;
               _this.checkMarkerCreationStatus()
            })
            .catch(error => {
                console.error('An error occurred:', error);
            });
        },
          createCloseMarkers() {
            let _this = this;
            let color:any = '';
            let promises = [];
            let categoryHierarchy = useCategoriesStore().categoryHierarchy;
            let poiCategories = useCategoriesStore().poiCategories;

            for(let [key,value] of categoryHierarchy.entries()) {

              let category:any = poiCategories.get(key);
              if (category && category.hasOwnProperty('cssColor')) {
                color = category.cssColor;
                this.closeMarkersCount = 0;
              }

              let closeIconName = 'close-' + key;

              let promise = new Promise((resolve, reject) => {
                let colorStart = getComputedStyle(document.body).getPropertyValue(color+'-start');
                let colorEnd = getComputedStyle(document.body).getPropertyValue(color+'-stop');

                      
                let markerKey =  'marker-close-' + window.devicePixelRatio +'-'+ colorStart + colorEnd; 
                let saved = idb.getData(markerKey);

                 
                saved.then((imgdata) => {
              if(imgdata == null) {

                let node = document.createElement('div');

                node.innerHTML = `
                <svg width="110" height="110" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_i_2258_58616)">
                <circle cx="19.5" cy="19.5" r="19.5" fill="url(#paint0_linear_2258_58616)"/>
                </g>
                <path d="M25.1094 23.6406C25.6172 24.1094 25.6172 24.9297 25.1094 25.3984C24.875 25.6328 24.5625 25.75 24.25 25.75C23.8984 25.75 23.5859 25.6328 23.3516 25.3984L19.25 21.2969L15.1094 25.3984C14.875 25.6328 14.5625 25.75 14.25 25.75C13.8984 25.75 13.5859 25.6328 13.3516 25.3984C12.8438 24.9297 12.8438 24.1094 13.3516 23.6406L17.4531 19.5L13.3516 15.3984C12.8438 14.9297 12.8438 14.1094 13.3516 13.6406C13.8203 13.1328 14.6406 13.1328 15.1094 13.6406L19.25 17.7422L23.3516 13.6406C23.8203 13.1328 24.6406 13.1328 25.1094 13.6406C25.6172 14.1094 25.6172 14.9297 25.1094 15.3984L21.0078 19.5391L25.1094 23.6406Z" fill="white"/>
                <defs>
                <filter id="filter0_i_2258_58616" x="0" y="0" width="39" height="39" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="2.5"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2258_58616"/>
                </filter>
                <linearGradient id="paint0_linear_2258_58616" x1="19.5" y1="0" x2="19.5" y2="39" gradientUnits="userSpaceOnUse">
                <stop stop-color="${colorStart}"/>
                <stop offset="1" stop-color="${colorEnd}"/>
                </linearGradient>
                </defs>
                </svg>                
                `;
   
                try {
                  toPng(node,{ width: 110, height: 110 })
                  .then(function (dataUrl) {
                    
                     _this.closeMarkers.set(closeIconName, dataUrl);
                      _this.closeMarkersCount++;
                      idb.saveData(dataUrl, markerKey);
                      resolve(true);
                  })
                  .catch(function (error) {
                    //reject(error);
                    console.log("node error",node);
                    resolve(true);
                    console.error('oops, something went wrong! ', error);
                    console.log("something wrong on marker id:",key )
                  });

              } catch (error) {
                 resolve(true);
                  console.error('oops, something went wrong!', error);
              }


            }else{

              _this.closeMarkers.set(closeIconName, imgdata);
              _this.closeMarkersCount++;
              resolve(true);
            }


          }); 



            });
            promises.push(promise);
            }
          

            Promise.all(promises)
            .then(() => {
              console.log("close markers created its done");
                _this.closeMarkersCreated = true;
                _this.checkMarkerCreationStatus();
            })
            .catch(error => {
                console.error('An error occurred:', error);
            });
          },
          createCloseEventCategoriesMarkers() {
            let _this = this;
            let color:any = '';
            let promises = [];
            let categoryHierarchy = new Map(useCategoriesStore().categoryHierarchy);
            let poiCategories = useCategoriesStore().poiCategories;

            categoryHierarchy.set('marker-with-opened-event',['1']);
            for(let [key,value] of categoryHierarchy.entries()) {

              let category:any = poiCategories.get(key);
              if (category && category.hasOwnProperty('cssColor')) {
                color = category.cssColor;
                this.closeMarkersWithEventCount = 0;
              }

              let closeIconName = 'with-event-' + key;

              let promise = new Promise((resolve, reject) => {
                let colorStart = getComputedStyle(document.body).getPropertyValue(color+'-start');
                let colorEnd = getComputedStyle(document.body).getPropertyValue(color+'-stop');

                
                let markerKey =  'marker-with-event-close-' + window.devicePixelRatio +'-'+ colorStart + colorEnd; 
                let saved = idb.getData(markerKey);

                 
                saved.then((imgdata) => {
              if(imgdata == null) {

                let node = document.createElement('div');
                
                if(key === 'marker-with-opened-event') {
                  node.innerHTML = `
                  <svg width="110" height="110" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.1623 18.5345H23.5355V17.2809H22.282V18.5345H18.5213V17.2809H17.2677V18.5345H14.7606V16.0273H13.507V18.5345H12.8802C12.714 18.5345 12.5545 18.6005 12.437 18.7181C12.3195 18.8356 12.2534 18.995 12.2534 19.1613C12.2534 19.3275 12.3195 19.4869 12.437 19.6045C12.5545 19.722 12.714 19.788 12.8802 19.788H24.1623C24.3286 19.788 24.488 19.722 24.6055 19.6045C24.7231 19.4869 24.7891 19.3275 24.7891 19.1613C24.7891 18.995 24.7231 18.8356 24.6055 18.7181C24.488 18.6005 24.3286 18.5345 24.1623 18.5345Z" fill="white"/>
                  <path d="M17.2674 16.0263H23.5353C23.7015 16.0263 23.8609 15.9603 23.9785 15.8427C24.096 15.7252 24.162 15.5658 24.162 15.3995C24.162 15.2333 24.096 15.0739 23.9785 14.9563C23.8609 14.8388 23.7015 14.7728 23.5353 14.7728V12.8924C23.5353 12.7262 23.4692 12.5668 23.3517 12.4492C23.2341 12.3317 23.0747 12.2656 22.9085 12.2656H17.8942C17.728 12.2656 17.5685 12.3317 17.451 12.4492C17.3334 12.5668 17.2674 12.7262 17.2674 12.8924V14.7728C17.1012 14.7728 16.9418 14.8388 16.8242 14.9563C16.7067 15.0739 16.6406 15.2333 16.6406 15.3995C16.6406 15.5658 16.7067 15.7252 16.8242 15.8427C16.9418 15.9603 17.1012 16.0263 17.2674 16.0263Z" fill="white"/>
                  <path d="M14.1338 14.7734C15.1723 14.7734 16.0141 13.0896 16.0141 11.0127C16.0141 8.93568 15.1723 7.25195 14.1338 7.25195C13.0953 7.25195 12.2534 8.93568 12.2534 11.0127C12.2534 13.0896 13.0953 14.7734 14.1338 14.7734Z" fill="white"/>
                  <rect x="19" y="24" width="12" height="12" fill="white"/>
                  <path d="M25.0005 21.002C23.2204 21.002 21.4802 21.5298 20.0001 22.5188C18.52 23.5078 17.3664 24.9135 16.6851 26.5581C16.0039 28.2027 15.8257 30.0124 16.1729 31.7583C16.5202 33.5043 17.3774 35.108 18.6362 36.3667C19.8949 37.6255 21.4987 38.4827 23.2446 38.83C24.9905 39.1773 26.8002 38.999 28.4448 38.3178C30.0895 37.6366 31.4951 36.483 32.4841 35.0028C33.4731 33.5227 34.001 31.7826 34.001 30.0024C34.001 28.8205 33.7682 27.6501 33.3159 26.5581C32.8635 25.4661 32.2006 24.4739 31.3648 23.6381C30.529 22.8024 29.5368 22.1394 28.4448 21.6871C27.3528 21.2348 26.1825 21.002 25.0005 21.002ZM28.1147 31.8025C27.9959 32.0084 27.8006 32.159 27.5713 32.2214C27.3419 32.2837 27.0973 32.2529 26.8906 32.1356L24.5505 30.7855L24.4695 30.7045L24.3255 30.5875C24.2903 30.5429 24.2601 30.4946 24.2355 30.4435C24.2061 30.3951 24.182 30.3439 24.1634 30.2905C24.139 30.2332 24.1238 30.1725 24.1184 30.1104L24.1004 30.0024V25.5022C24.1004 25.2635 24.1953 25.0346 24.3641 24.8658C24.5329 24.697 24.7618 24.6021 25.0005 24.6021C25.2392 24.6021 25.4681 24.697 25.6369 24.8658C25.8057 25.0346 25.9005 25.2635 25.9005 25.5022V29.4804L27.7906 30.5695C27.893 30.629 27.9827 30.7082 28.0544 30.8025C28.1261 30.8967 28.1785 31.0042 28.2086 31.1187C28.2387 31.2333 28.2459 31.3527 28.2298 31.47C28.2137 31.5873 28.1745 31.7003 28.1147 31.8025Z" fill="url(#paint0_linear_6573_42032)"/>
                  <g filter="url(#filter0_i_6573_42032)">
                  <circle cx="19" cy="15" r="15" fill="url(#paint1_linear_6573_42032)"/>
                  </g>
                  <path d="M27.0135 15.1217C27.0135 15.7665 26.512 16.2681 25.903 16.2681H14.9049L18.6664 20.0655C19.1322 20.4954 19.1322 21.2477 18.6664 21.6776C18.4515 21.8925 18.1649 22 17.8783 22C17.5559 22 17.2693 21.8925 17.0543 21.6776L11.3224 15.9457C10.8567 15.5158 10.8567 14.7635 11.3224 14.3336L17.0543 8.60165C17.4842 8.13593 18.2365 8.13593 18.6664 8.60165C19.1322 9.03155 19.1322 9.78386 18.6664 10.2138L14.9049 13.9753H25.903C26.512 13.9753 27.0135 14.5127 27.0135 15.1217Z" fill="white"/>
                  <rect x="19" y="24" width="12" height="12" fill="white"/>
                  <path d="M25.0005 21.002C23.2204 21.002 21.4802 21.5298 20.0001 22.5188C18.52 23.5078 17.3664 24.9135 16.6851 26.5581C16.0039 28.2027 15.8257 30.0124 16.1729 31.7583C16.5202 33.5043 17.3774 35.108 18.6362 36.3667C19.8949 37.6255 21.4987 38.4827 23.2446 38.83C24.9905 39.1773 26.8002 38.999 28.4448 38.3178C30.0895 37.6366 31.4951 36.483 32.4841 35.0028C33.4731 33.5227 34.001 31.7826 34.001 30.0024C34.001 28.8205 33.7682 27.6501 33.3159 26.5581C32.8635 25.4661 32.2006 24.4739 31.3648 23.6381C30.529 22.8024 29.5368 22.1394 28.4448 21.6871C27.3528 21.2348 26.1825 21.002 25.0005 21.002ZM28.1147 31.8025C27.9959 32.0084 27.8006 32.159 27.5713 32.2214C27.3419 32.2837 27.0973 32.2529 26.8906 32.1356L24.5505 30.7855L24.4695 30.7045L24.3255 30.5875C24.2903 30.5429 24.2601 30.4946 24.2355 30.4435C24.2061 30.3951 24.182 30.3439 24.1634 30.2905C24.139 30.2332 24.1238 30.1725 24.1184 30.1104L24.1004 30.0024V25.5022C24.1004 25.2635 24.1953 25.0346 24.3641 24.8658C24.5329 24.697 24.7618 24.6021 25.0005 24.6021C25.2392 24.6021 25.4681 24.697 25.6369 24.8658C25.8057 25.0346 25.9005 25.2635 25.9005 25.5022V29.4804L27.7906 30.5695C27.893 30.629 27.9827 30.7082 28.0544 30.8025C28.1261 30.8967 28.1785 31.0042 28.2086 31.1187C28.2387 31.2333 28.2459 31.3527 28.2298 31.47C28.2137 31.5873 28.1745 31.7003 28.1147 31.8025Z" fill="url(#paint2_linear_6573_42032)"/>
                  <defs>
                  <filter id="filter0_i_6573_42032" x="4" y="0" width="30" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="1.92308"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6573_42032"/>
                  </filter>
                  <linearGradient id="paint0_linear_6573_42032" x1="25.0005" y1="21.002" x2="25.0005" y2="39.0029" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#D6779D"/>
                  <stop offset="1" stop-color="#BD567B"/>
                  </linearGradient>
                  <linearGradient id="paint1_linear_6573_42032" x1="19" y1="0" x2="19" y2="30" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#D6779D"/>
                  <stop offset="1" stop-color="#BD567B"/>
                  </linearGradient>
                  <linearGradient id="paint2_linear_6573_42032" x1="25.0005" y1="21.002" x2="25.0005" y2="39.0029" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#D6779D"/>
                  <stop offset="1" stop-color="#BD567B"/>
                  </linearGradient>
                  </defs>
                  </svg>
                  `;
                }else{
                node.innerHTML = `
                <svg width="110" height="110"  viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.1623 18.5345H23.5355V17.2809H22.282V18.5345H18.5213V17.2809H17.2677V18.5345H14.7606V16.0273H13.507V18.5345H12.8802C12.714 18.5345 12.5545 18.6005 12.437 18.7181C12.3195 18.8356 12.2534 18.995 12.2534 19.1613C12.2534 19.3275 12.3195 19.4869 12.437 19.6045C12.5545 19.722 12.714 19.788 12.8802 19.788H24.1623C24.3286 19.788 24.488 19.722 24.6055 19.6045C24.7231 19.4869 24.7891 19.3275 24.7891 19.1613C24.7891 18.995 24.7231 18.8356 24.6055 18.7181C24.488 18.6005 24.3286 18.5345 24.1623 18.5345Z" fill="white"/>
<path d="M17.2674 16.0263H23.5353C23.7015 16.0263 23.8609 15.9603 23.9785 15.8427C24.096 15.7252 24.162 15.5658 24.162 15.3995C24.162 15.2333 24.096 15.0739 23.9785 14.9563C23.8609 14.8388 23.7015 14.7728 23.5353 14.7728V12.8924C23.5353 12.7262 23.4692 12.5668 23.3517 12.4492C23.2341 12.3317 23.0747 12.2656 22.9085 12.2656H17.8942C17.728 12.2656 17.5685 12.3317 17.451 12.4492C17.3334 12.5668 17.2674 12.7262 17.2674 12.8924V14.7728C17.1012 14.7728 16.9418 14.8388 16.8242 14.9563C16.7067 15.0739 16.6406 15.2333 16.6406 15.3995C16.6406 15.5658 16.7067 15.7252 16.8242 15.8427C16.9418 15.9603 17.1012 16.0263 17.2674 16.0263Z" fill="white"/>
<path d="M14.1338 14.7734C15.1723 14.7734 16.0141 13.0896 16.0141 11.0127C16.0141 8.93568 15.1723 7.25195 14.1338 7.25195C13.0953 7.25195 12.2534 8.93568 12.2534 11.0127C12.2534 13.0896 13.0953 14.7734 14.1338 14.7734Z" fill="white"/>
<rect x="19" y="24" width="12" height="12" fill="white"/>
<path d="M25.0005 21.002C23.2204 21.002 21.4802 21.5298 20.0001 22.5188C18.52 23.5078 17.3664 24.9135 16.6851 26.5581C16.0039 28.2027 15.8257 30.0124 16.1729 31.7583C16.5202 33.5043 17.3774 35.108 18.6362 36.3667C19.8949 37.6255 21.4987 38.4827 23.2446 38.83C24.9905 39.1773 26.8002 38.999 28.4448 38.3178C30.0895 37.6366 31.4951 36.483 32.4841 35.0028C33.4731 33.5227 34.001 31.7826 34.001 30.0024C34.001 28.8205 33.7682 27.6501 33.3159 26.5581C32.8635 25.4661 32.2006 24.4739 31.3648 23.6381C30.529 22.8024 29.5368 22.1394 28.4448 21.6871C27.3528 21.2348 26.1825 21.002 25.0005 21.002ZM28.1147 31.8025C27.9959 32.0084 27.8006 32.159 27.5713 32.2214C27.3419 32.2837 27.0973 32.2529 26.8906 32.1356L24.5505 30.7855L24.4695 30.7045L24.3255 30.5875C24.2903 30.5429 24.2601 30.4946 24.2355 30.4435C24.2061 30.3951 24.182 30.3439 24.1634 30.2905C24.139 30.2332 24.1238 30.1725 24.1184 30.1104L24.1004 30.0024V25.5022C24.1004 25.2635 24.1953 25.0346 24.3641 24.8658C24.5329 24.697 24.7618 24.6021 25.0005 24.6021C25.2392 24.6021 25.4681 24.697 25.6369 24.8658C25.8057 25.0346 25.9005 25.2635 25.9005 25.5022V29.4804L27.7906 30.5695C27.893 30.629 27.9827 30.7082 28.0544 30.8025C28.1261 30.8967 28.1785 31.0042 28.2086 31.1187C28.2387 31.2333 28.2459 31.3527 28.2298 31.47C28.2137 31.5873 28.1745 31.7003 28.1147 31.8025Z" fill="url(#paint0_linear_6528_41970)"/>
<g filter="url(#filter0_i_6528_41970)">
<circle cx="19" cy="15" r="15" fill="url(#paint1_linear_6528_41970)"/>
</g>
<path d="M24.1056 18.9191C24.5713 19.349 24.5713 20.1013 24.1056 20.5312C23.8906 20.7461 23.604 20.8536 23.3174 20.8536C22.995 20.8536 22.7084 20.7461 22.4935 20.5312L18.7319 16.7696L14.9345 20.5312C14.7196 20.7461 14.433 20.8536 14.1464 20.8536C13.824 20.8536 13.5374 20.7461 13.3224 20.5312C12.8567 20.1013 12.8567 19.349 13.3224 18.9191L17.084 15.1217L13.3224 11.3601C12.8567 10.9302 12.8567 10.1779 13.3224 9.74804C13.7523 9.28232 14.5046 9.28232 14.9345 9.74804L18.7319 13.5096L22.4935 9.74804C22.9234 9.28232 23.6757 9.28232 24.1056 9.74804C24.5713 10.1779 24.5713 10.9302 24.1056 11.3601L20.344 15.1575L24.1056 18.9191Z" fill="white"/>
<rect x="19" y="24" width="12" height="12" fill="white"/>
<path d="M25.0005 21.002C23.2204 21.002 21.4802 21.5298 20.0001 22.5188C18.52 23.5078 17.3664 24.9135 16.6851 26.5581C16.0039 28.2027 15.8257 30.0124 16.1729 31.7583C16.5202 33.5043 17.3774 35.108 18.6362 36.3667C19.8949 37.6255 21.4987 38.4827 23.2446 38.83C24.9905 39.1773 26.8002 38.999 28.4448 38.3178C30.0895 37.6366 31.4951 36.483 32.4841 35.0028C33.4731 33.5227 34.001 31.7826 34.001 30.0024C34.001 28.8205 33.7682 27.6501 33.3159 26.5581C32.8635 25.4661 32.2006 24.4739 31.3648 23.6381C30.529 22.8024 29.5368 22.1394 28.4448 21.6871C27.3528 21.2348 26.1825 21.002 25.0005 21.002ZM28.1147 31.8025C27.9959 32.0084 27.8006 32.159 27.5713 32.2214C27.3419 32.2837 27.0973 32.2529 26.8906 32.1356L24.5505 30.7855L24.4695 30.7045L24.3255 30.5875C24.2903 30.5429 24.2601 30.4946 24.2355 30.4435C24.2061 30.3951 24.182 30.3439 24.1634 30.2905C24.139 30.2332 24.1238 30.1725 24.1184 30.1104L24.1004 30.0024V25.5022C24.1004 25.2635 24.1953 25.0346 24.3641 24.8658C24.5329 24.697 24.7618 24.6021 25.0005 24.6021C25.2392 24.6021 25.4681 24.697 25.6369 24.8658C25.8057 25.0346 25.9005 25.2635 25.9005 25.5022V29.4804L27.7906 30.5695C27.893 30.629 27.9827 30.7082 28.0544 30.8025C28.1261 30.8967 28.1785 31.0042 28.2086 31.1187C28.2387 31.2333 28.2459 31.3527 28.2298 31.47C28.2137 31.5873 28.1745 31.7003 28.1147 31.8025Z" fill="url(#paint2_linear_6528_41970)"/>
<defs>
<filter id="filter0_i_6528_41970" x="4" y="0" width="30" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="1.92308"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_6528_41970"/>
</filter>
<linearGradient id="paint0_linear_6528_41970" x1="25.0005" y1="21.002" x2="25.0005" y2="39.0029" gradientUnits="userSpaceOnUse">
<stop stop-color="#D6779D"/>
<stop offset="1" stop-color="#BD567B"/>
</linearGradient>
<linearGradient id="paint1_linear_6528_41970" x1="19" y1="0" x2="19" y2="30" gradientUnits="userSpaceOnUse">
<stop stop-color="${colorStart}"/>
<stop offset="1" stop-color="${colorEnd}"/>
</linearGradient>
<linearGradient id="paint2_linear_6528_41970" x1="25.0005" y1="21.002" x2="25.0005" y2="39.0029" gradientUnits="userSpaceOnUse">
<stop stop-color="#D6779D"/>
<stop offset="1" stop-color="#BD567B"/>
</linearGradient>
</defs>
</svg>
`;
                }
   
                try {
                  toPng(node,{ width: 110, height: 110 })
                  .then(function (dataUrl) {
                    
                     _this.closeMarkersWithEvent.set(closeIconName, dataUrl);
                      _this.closeMarkersWithEventCount++;
                      idb.saveData(dataUrl, markerKey);
                      resolve(true);
                  })
                  .catch(function (error) {
                    //reject(error);
                    console.log("node error",node);
                    resolve(true);
                    console.error('oops, something went wrong! ', error);
                    console.log("something wrong on marker id:",key )
                  });

              } catch (error) {
                 resolve(true);
                  console.error('oops, something went wrong!', error);
              }


            }else{
              _this.closeMarkersWithEvent.set(closeIconName, imgdata);
              _this.closeMarkersWithEventCount++;
              resolve(true);
            }


          }); 



            });
            promises.push(promise);
            }
          

            Promise.all(promises)
            .then(() => {
              console.log("close markers with category event its done");
                _this.closeMarkersWithEventCreated = true;
                _this.checkMarkerCreationStatus();
            })
            .catch(error => {
                console.error('An error occurred:', error);
            });
          },
          createEventMarkers() {
            let promises = [];
            let _this = this;
            let promise = new Promise((resolve, reject) => {                    
              let markerKey =  'marker-event-' + window.devicePixelRatio; 
              let saved = idb.getData(markerKey);
              saved.then((imgdata) => {
                if(imgdata == null) {

                    let node = document.createElement('div');

                    node.innerHTML = `
                    <svg width="110" height="110" viewBox="0 0 30 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_i_6095_68406)">
                    <path d="M3.33013 24.5019C-1.48835 18.516 -1.02967 9.84783 4.39343 4.40735C10.2512 -1.46919 19.7487 -1.46908 25.6064 4.40746C31.0295 9.84788 31.4884 18.516 26.6702 24.502L15.7797 38.0322C15.3794 38.5295 14.622 38.5295 14.2217 38.0323L3.33013 24.5019Z" fill="url(#paint0_linear_6095_68406)"/>
                    </g>
                    <path d="M14.5201 7.25391C13.2807 7.25391 12.0691 7.62144 11.0385 8.31003C10.008 8.99862 9.20475 9.97734 8.73044 11.1224C8.25613 12.2675 8.13203 13.5275 8.37383 14.7431C8.61563 15.9588 9.21248 17.0754 10.0889 17.9518C10.9653 18.8282 12.0819 19.425 13.2975 19.6668C14.5131 19.9086 15.7732 19.7845 16.9182 19.3102C18.0633 18.8359 19.042 18.0327 19.7306 17.0021C20.4192 15.9716 20.7868 14.76 20.7868 13.5206C20.7868 12.6976 20.6247 11.8827 20.3097 11.1224C19.9948 10.3621 19.5332 9.67128 18.9513 9.08937C18.3694 8.50746 17.6785 8.04586 16.9182 7.73093C16.1579 7.416 15.343 7.25391 14.5201 7.25391ZM16.6884 14.7739C16.6057 14.9172 16.4697 15.0221 16.31 15.0655C16.1503 15.1089 15.98 15.0875 15.8361 15.0058L14.2068 14.0658L14.1504 14.0094L14.0501 13.9279C14.0256 13.8969 14.0046 13.8633 13.9874 13.8276C13.967 13.794 13.9502 13.7583 13.9373 13.7211C13.9202 13.6813 13.9097 13.639 13.906 13.5958L13.8934 13.5206V10.3872C13.8934 10.221 13.9594 10.0616 14.077 9.94412C14.1945 9.8266 14.3539 9.76057 14.5201 9.76057C14.6863 9.76057 14.8457 9.8266 14.9632 9.94412C15.0807 10.0616 15.1468 10.221 15.1468 10.3872V13.1571L16.4628 13.9154C16.534 13.9568 16.5964 14.012 16.6464 14.0776C16.6963 14.1432 16.7328 14.2181 16.7538 14.2978C16.7747 14.3776 16.7797 14.4607 16.7685 14.5424C16.7573 14.6241 16.73 14.7028 16.6884 14.7739Z" fill="white"/>
                    <defs>
                    <filter id="filter0_i_6095_68406" x="0" y="0" width="30" height="38.4043" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6095_68406"/>
                    </filter>
                    <linearGradient id="paint0_linear_6095_68406" x1="15" y1="0" x2="15" y2="39" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D6779D"/>
                    <stop offset="1" stop-color="#BD567B"/>
                    </linearGradient>
                    </defs>
                    </svg>
                    
                    `;
        
                    try {
                      toPng(node,{ width: 110, height: 110 })
                      .then(function (dataUrl) {
                          _this.eventMarkers.set('eventMarker', dataUrl);
                          resolve(true);
                        })
                      .catch(function (error) {
                        //reject(error);
                        console.log("node error",node);
                        resolve(true);
                        console.error('oops, something went wrong! ', error);
                        console.log("something wrong on event" )
                      });

                    }catch (error) {
                        resolve(true);
                        console.error('oops, something went wrong!', error);
                    }
                }else{
                  _this.eventMarkers.set('eventMarker', imgdata);
                  resolve(true);
                }
          }); 
        });
        promises.push(promise);
            
          

        Promise.all(promises)
        .then(() => {
          _this.eventMarkersCreated = true;
          _this.checkMarkerCreationStatus();
          console.log("close markers created its done");
        })
        .catch(error => {
            console.error('An error occurred:', error);
        });
        },
        createEventCloseMarkers() {
          let promises = [];
          let _this = this;
          let promise = new Promise((resolve, reject) => {                    
            let markerKey =  'close-event-' + window.devicePixelRatio; 
            let saved = idb.getData(markerKey);
            saved.then((imgdata) => {
              if(imgdata == null) {

                  let node = document.createElement('div');

                  node.innerHTML = `
                  <svg width="110" height="110" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_i_6017_62879)">
                  <circle cx="19.5" cy="19.5" r="19.5" fill="url(#paint0_linear_6017_62879)"/>
                  </g>
                  <path d="M27.4609 19.5C27.4609 20.2031 26.9141 20.75 26.25 20.75H14.2578L18.3594 24.8906C18.8672 25.3594 18.8672 26.1797 18.3594 26.6484C18.125 26.8828 17.8125 27 17.5 27C17.1484 27 16.8359 26.8828 16.6016 26.6484L10.3516 20.3984C9.84375 19.9297 9.84375 19.1094 10.3516 18.6406L16.6016 12.3906C17.0703 11.8828 17.8906 11.8828 18.3594 12.3906C18.8672 12.8594 18.8672 13.6797 18.3594 14.1484L14.2578 18.25H26.25C26.9141 18.25 27.4609 18.8359 27.4609 19.5Z" fill="white"/>
                  <defs>
                  <filter id="filter0_i_6017_62879" x="0" y="0" width="39" height="39" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="2.5"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6017_62879"/>
                  </filter>
                  <linearGradient id="paint0_linear_6017_62879" x1="19.5" y1="0" x2="19.5" y2="39" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#D6779D"/>
                  <stop offset="1" stop-color="#BD567B"/>
                  </linearGradient>
                  </defs>
                  </svg>                                   
                  `;
                  try {
                    toPng(node,{ width: 110, height: 110 })
                    .then(function (dataUrl) {
                        _this.eventMarkersClose.set('closeEventMarker', dataUrl);
                        resolve(true);
                      })
                    .catch(function (error) {
                      //reject(error);
                      console.log("node error",node);
                      resolve(true);
                      console.error('oops, something went wrong! ', error);
                      console.log("something wrong on event" )
                    });

                  }catch (error) {
                      resolve(true);
                      console.error('oops, something went wrong!', error);
                  }
              }else{
                _this.eventMarkersClose.set('closeEventMarker', imgdata);
                resolve(true);
              }
        }); 
      });
      promises.push(promise);
          
        

      Promise.all(promises)
      .then(() => {
        _this.eventMarkersCloseCreated = true;
        _this.checkMarkerCreationStatus();
        console.log("close event markers created its done");
      })
      .catch(error => {
          console.error('An error occurred:', error);
      });
      }
    },
    getters: {
      getMarkerImage: (state) => (id: string, size: string) => {
        const appStore = useMapStoreData();
        const destinationId = appStore.destinationId;
        const markers = appStore.markers;
        //debugger;
        // Check if marker has image
        /*if (!state.markerThumbnails.has(id) || state.markerThumbnails.get(id) === null || markers === null || (markers as { [key: string]: any })[id] === null) {
          return false;
        }*/
        
        if ((markers as { [key: string]: any })[id].properties.presentation === null) {
          return false;
        }
      
        const basic = (markers as { [key: string]: any })[id].properties.presentation.basic;
        if (basic === '') {
          return false;
        }
      
        // Check if basic content has gallery
        if (!basic.content.hasOwnProperty('gallery')) {
          return false;
        }
      
        // Check if gallery has files
        if (basic.content.gallery.files.length === 0) {
          return false;
        }
      
        // Create image URLs
        const images = basic.content.gallery.files.map((slide: any) => {
          return `${appStore.publicUrl}repo/${destinationId}/${slide.hash}-${size}.jpg`;
        });
      
        return images;
      },
      getMarkerVideos: (state) => (id: string) => {
        const appStore = useMapStoreData();
        const destinationId = appStore.destinationId;
        const markers = appStore.markers;

        //if (!state.markerThumbnails.has(id) || state.markerThumbnails.get(id) === null || markers === null || (markers as { [key: string]: any })[id] === null) {
          //check if marker has properties.presentation.basic.content.video
          if ((markers as { [key: string]: any })[id].properties.presentation === null) {
            return false;
          }
        
          const basic = (markers as { [key: string]: any })[id].properties.presentation.basic;
          if (basic === '') {
            return false;
          }
          
          // Check if basic content has video
          if (!basic.content.hasOwnProperty('video')) {
            return false;
          }
        
          // Check if video has files
          if (basic.content.video.files.length === 0) {
            return false;
          }
          
          // Create image URLs
          const images = basic.content.video.files.map((video: any) => {
            return `${appStore.publicUrl}repo/${destinationId}/${video.hash}.mp4`;
          });
        
          return images;
        //}
      },
    }
});
