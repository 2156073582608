import { defineStore } from 'pinia';
import { useCategoriesStore,useScreensaverStore, useAnalyticsStore } from '@/stores/index'
import { useMarkersStore } from '@/stores/create-markers-store'

interface Languages {
  [key: string]: any; 
}  
  interface PublicUrlState {
    publicUrl: string;
  }

  interface QuillOperation {
    insert: string;
    attributes?: {
        bold?: boolean;
        italic?: boolean;
        color?: string;
    };
}

//converting quill delta to html
interface QuillDelta {
    ops: QuillOperation[];
}

interface Properties {
  description: string;
  [key: string]: any;
}

interface Feature {
  properties: Properties;
  [key: string]: any;
}
//end converting quill delta to html

type Data = { [key: string]: Feature };

var serverTimestamp = 0 as number;
var serverTimestampCommon = 0 as number; 


export const useMapStoreData = defineStore('mapData', {
    state: () => ({
      markers: null as Object| null,
      clusters: null as Object| null,
      dataSelectors: null as Object | null,
      options: null as any | null,
      modules: null as any | null,
      defaultRoute: '/' as string,
      languages: {} as Languages,
      allLanguages: [] as Array<string>,
      routes:[] as Array<string>,
      common: {} as Languages,
      destinationId: null as string | null,
      currentLanguage: null as string | null,
      fallbackLang:null as any,
      popularity: {} as any,
      imagesLoaded:0 as number,
      showMap: false as boolean,
      countCategoriesMarkers:0 as number,
      allMarkersNumber:0 as number,
      scenariosImage: '' as string,
      mapImage: '' as string,
      /*screensaverVideo:'' as string,
      screensaverImage:[] as Array<string>,*/
      publicUrl: '' as string,
      activePoi:null as any,
      appReady: false as boolean,
      hideMap: false as boolean,
      //serverTimestamp: 0 as number,
      iframeOpened: false as boolean,
      serverTimestamp: 0 as number,
      isOnline: false as boolean | null,
      hideMapFromHome: false as boolean,
      //serverTimestampCommon:  0 as number
    }),
  
    actions: {

        async checkForUpdates(){
          
          //var needRestart = false;
          //check for memory leaks
        let memoryInfo = useAnalyticsStore().getMemory();
         if(memoryInfo != null){
           if(memoryInfo.usedJSHeapSize/memoryInfo.jsHeapSizeLimit > 0.7){
            console.log('memory overload reszart');
            useAnalyticsStore().setStatusData('memoryInfo', memoryInfo);
            useAnalyticsStore().report_js_error(
              'Prisilni reload zaradi memory',JSON.stringify(memoryInfo),0,0, null
              ,function(){
                console.log('reloadam');
                window.location.reload();
            });          
            return;
           }
         } 

          let rnds = Math.floor(Math.random() * 99999999) + 1;
          try{
          let reponse = await fetch(this.publicUrl + 'data/'+ this.destinationId +'/timestamp.txt?v='+rnds);
          let responseText = await reponse.text(); 
          let NewServerTimestamp = parseInt(responseText);
          if(Number.isNaN(NewServerTimestamp)) NewServerTimestamp = 0;
          if(NewServerTimestamp > this.serverTimestamp){
            console.log('timestamp new data reloading');
            //needRestart = true;
          }else{
            console.log("data no changes NewServerTimestamp:"+NewServerTimestamp," old one"+this.serverTimestamp);
          }
         } catch{}
        },

        async  loadJsonWithRetry(url: string) {
          let attempt = 1;
          const delay = 3000;
          while (true) {
            attempt++
            try {
              const response = await fetch(url);
              if (!response.ok) {
                //throw new Error(`Failed to fetch: ${response.statusText}`);
                await new Promise(resolve => setTimeout(resolve, delay));
              }
              //const data = await response.json();
              const data =  response;
              return data;
            } catch (error) {
              console.warn(`Attempt ${attempt}: Failed to load JSON`);
              await new Promise(resolve => setTimeout(resolve, delay)); // Wait before retry
            }
          }
        },


                /**
         * Loads JSON files from a specified path and sets the data for markers, clusters, data selectors, options, languages, startpoint data, fallback language, categories, CSS colors, and creates markers.
         * @param id - The ID of the destination.
         * @param startpoint - The starting point of the destination.
         * @returns Promise<void>
         */

        async loadJSONFiles(id:string | null,startpoint:string | null) {
            const setData = useCategoriesStore();
            const createMarkersStore = useMarkersStore();
            try {
              
              this.setPublicUrl();
              let _this = this;
              this.destinationId = id; 
              
              console.log('loadJSONFiles');
              var serverTimestamp = 0;
              let path =  'data/'+ id;
              let startpointKey = '/startpoint-' + startpoint + '.json';
              
              let rnds = Math.floor(Math.random() * 99999999) + 1;
              try{
                let serverTimestampResponse =  await fetch(this.publicUrl + path +'/timestamp.txt?v='+rnds);
                let serverTimestamp1 = await serverTimestampResponse.text();
                serverTimestamp = parseInt(serverTimestamp1);
              }catch{
                
              }
              if(Number.isNaN(serverTimestamp) || serverTimestamp == 0){ 
                serverTimestamp = Math.floor(Math.random() * 99999999) + 1;
              }else{
                this.serverTimestamp = serverTimestamp;
              }
              console.log('timestamp '+ serverTimestamp);
              console.log('publicUrl '+ this.publicUrl);
              console.log('path '+ path);
              
              const response1 = await this.loadJsonWithRetry(this.publicUrl + path +'/data_poi.json?v=' + serverTimestamp);
              const response2 = await  this.loadJsonWithRetry(this.publicUrl + path +'/geojson_min.json??v=' + serverTimestamp);
              const response3 = await this.loadJsonWithRetry(this.publicUrl + path +'/selectors.json??v=' + serverTimestamp);
              const startpointFile =await this.loadJsonWithRetry(this.publicUrl + path + startpointKey + '?v=' + serverTimestamp);

              
              
              useAnalyticsStore().setStatusData('serverTimestamp', serverTimestamp);


              this.markers = await response1.json();
              this.clusters = await response2.json();
              this.dataSelectors = await response3.json();

              this.options = await startpointFile.json();
              if(_this.options?.modules != null) {
                this.modules = new Map(Object.entries(_this.options?.modules));
              }


              //TODO default opcije vse bitne, ne samo directionsType
              ////////////////////////
              if (this.options.directionsType == undefined)  this.options.directionsType = 'walking';
              /////////////////////////

              await this.fitLanguages(path);

              this.allMarkersNumber = this.markers ? Object.keys(this.markers).length : 0; //get number of markers
              if(_this.options?.modules != null) {
                	this.setMapAndScenariosImage(); 
              }
              this.setScreensaver();
              setData.setCategories();
              setData.setCssColors(); 
              this.appReady = true;
              console.log('spura data naložen, dej screensejvr');
              //console.log('useMapStoreData.appReady')
              createMarkersStore.createMarkers();
              this.createRoutes();
            } catch (error) {
              console.error('Failed to load JSON files:', error);
            }
          },
          setPublicUrl(this: PublicUrlState) {
            if (import.meta.env.MODE === 'production') {
              console.log('production:', import.meta.env.VITE_PUBLIC_URL);
              this.publicUrl = import.meta.env.VITE_PUBLIC_URL;
            } else {
              console.log('Build-only variable:', import.meta.env.VITE_PUBLIC_URL);
              this.publicUrl = import.meta.env.VITE_PUBLIC_URL;
            }
          },
          setActivePoi(activePoi:any) {
            this.activePoi = activePoi;
          },
          poiPopularity(id:string) {
            let popularity:object = {};
            if(localStorage.getItem("popularitySimpl") != null) {
              popularity = JSON.parse(localStorage.getItem("popularitySimpl") || '{}');
              this.popularity = popularity;
            }

            if (id in popularity) {
              // Increment the value if the ID already exists
              this.popularity[id] += 1;
            } else {
              this.popularity[id] = 1;
            }
            localStorage.setItem("popularitySimpl", JSON.stringify(this.popularity));
          },
          setPopularityPoi() {
            let popularity:object = {};
            //debugger;
            if(localStorage.getItem("popularitySimpl") != null) {
              popularity = JSON.parse(localStorage.getItem("popularitySimpl") || '{}');
              this.popularity = popularity;
            }
          },
          setScreensaver() {
            let screensaver = this.options.options.screensaver;
            if(screensaver.length > 0) {
              if(this.options.options.screensaver[0].ext == 'mp4') {
                useScreensaverStore().screensaverVideo = this.returnRepoPath(screensaver[0].hash + '.' + screensaver[0].ext); 
              }else {
                screensaver.forEach((element:any) => {
                  useScreensaverStore().screensaverImage.push(this.returnRepoPath(element.hash + '.' + element.ext));
                });
              }
            }
          },
          setMapAndScenariosImage() {
            let scenariosThumbUrl = this.modules.has('scenarios') && this.modules.get('scenarios').thumb;
            this.scenariosImage = scenariosThumbUrl ? this.returnRepoPath(scenariosThumbUrl.hash + '.' + scenariosThumbUrl.ext) : '';
            
            let mapThumbUrl = this.modules.has('map') && this.modules.get('map').thumb;
            this.mapImage = this.modules.has('map') ? this.returnRepoPath(mapThumbUrl.hash + '.' +mapThumbUrl.ext) : '';
          },
          changeLanguage(language:string) {
            this.currentLanguage = language;
          },
          async fitLanguages(path:string) {
            try{
              let response = await fetch(this.publicUrl + 'data/common/i18n/timestamp.txt');
              let responseText = await response.text();
              serverTimestampCommon = parseInt(responseText);
            }catch{

            }
            if(Number.isNaN(serverTimestampCommon) || serverTimestampCommon == 0) serverTimestampCommon = Math.floor(Math.random() * 99999999) + 1;

            this.currentLanguage = JSON.parse(this.options?.languages)[0];
            if(this.options && this.options.languages){
              
              for(const element of JSON.parse(this.options.languages)) {
                console.log("element",element);
                try {
                  const fetchedlanguage = await fetch(this.publicUrl + path +'/locale_poi-' + element + '.json?v=' + serverTimestamp);
                  const destinationCommonText = await fetch(this.publicUrl + path +'/text-' + element + '.json?v=' + serverTimestamp);
                  if(fetchedlanguage.ok) {
                    
                    let languageCommon = await fetch(this.publicUrl + 'data/common/i18n/' + element + '.json?v=' + serverTimestampCommon);
                    let modules = [];
                    try {
                      const languuageModules = await fetch(this.publicUrl + path + '/locale_dest-' + element + '.json?v=' + serverTimestamp);
                      if(languuageModules.status != 404) {
                        console.log('sem ne smemo '+languuageModules.status);
                        modules = await languuageModules.json();
                      }
                    } catch (error) {
                      console.error('Failed to fetch locale_dest-', error);
                    }
                    const language = await fetchedlanguage.json();
                    const destinationCommon = await destinationCommonText.json();
                    const languagedescriptionConvert = this.convertDescriptionToHTML(language);
                    this.languages[element] = this.nestTranslationKeys(languagedescriptionConvert);
                    const common = await languageCommon.json();
                    this.common[element] = {
                      ...common,
                      ...destinationCommon,
                      ...modules,
                    };
                    this.allLanguages.push(element);
                   
                  }
                } catch (error) {
                  console.error('Failed to fetch language data for', element, error);
                }
              };
            } else {
              console.log('options or languages is null or undefined');
            }
           
            const fallbackLang = this.nestTranslationKeys(this.markers);
           
            this.fallbackLang = fallbackLang;
          },
          convertDescriptionToHTML(jsonData: Data): Data {
            const newJsonData: Data = jsonData; // Clone the original JSON
            
            for (let key in newJsonData) {

                if (newJsonData[key]?.description) {
                  if(this.isStringifiedQuillDelta(newJsonData[key].description)) {
                    try {
                      //check if newJsonData[key].description is stringified Quill Delta object

                      // Parse the stringified Quill Delta object
                        let quillDelta: QuillDelta = JSON.parse(newJsonData[key].description);
                      
                        // Initialize an empty string to hold the HTML
                        let htmlString = '';
        
                        // Loop through each operation in the Quill Delta object
                        quillDelta.ops.forEach(op => {
                            if (op.insert) {
                                if (op.attributes) {
                                    // Wrap the text in the appropriate HTML tags based on its attributes
                                    if (op.attributes.bold) {
                                        htmlString += `<b>${op.insert}</b>`;
                                    } else if (op.attributes.italic) {
                                        htmlString += `<i>${op.insert}</i>`;
                                    } else if(op.attributes.color) {
                                        htmlString += `<p style="color: ${op.attributes.color}">${op.insert}</p>`;
                                    }
                                    // Add more conditions here for other attributes as needed
                                } else {
                                    // No attributes, so wrap the text in a <p> tag
                                    htmlString += `<p>${op.insert}</p>`;
                                }
                            }
                        });
                        htmlString = htmlString.replace(/[{}@$|]/g, match => `{'${match}'}`);
                        // Replace the original description with the new HTML string
                        newJsonData[key].description = htmlString;
        
                    } catch (error) {
                      
                      // If parsing the description throws an error, leave the description as it is
                      console.log(`Failed to parse description for key ${key}: ${error}`);
                    }
                } else {
                  //if description is not stringified Quill Delta object is probably string;
                  newJsonData[key].description = newJsonData[key].description.replace(/[{}@$|]/g, (match: string) => `{'${match}'}`);
                }

                // if key is "" remove proprety from object
                if(newJsonData[key].title == "") {
                  delete newJsonData[key].title;
                }

              }
            }
        
            return newJsonData;
        },
        isStringifiedQuillDelta(str: string): boolean {
          try {
              const obj = JSON.parse(str);
              if (!obj.ops || !Array.isArray(obj.ops)) {
                  return false;
              }
      
              for (const op of obj.ops) {
                  if (!op.insert && (typeof op.insert !== "string" || typeof op.insert !== "object")) {
                      return false;
                  }
              }
              
              return true;
          } catch (e) {
              return false;
          }
      },
      nestTranslationKeys(flatMessages:any) {
        let nestedMessages = {};
      
        for (let key in flatMessages) {
          let parts = key.split(".");
          let target:any = nestedMessages;
      
          while (parts.length > 1) {
            let part:string | undefined = parts.shift();
            if(part !== undefined) {
              target = target[part] = target[part] || {};
            }
          }
      
          target[parts[0]] = flatMessages[key];
        }

        return nestedMessages;
      },
      createRoutes() {
        const modules: any = this.modules;
        if (modules === undefined || modules === null ) {
          console.log("no additional modules");
          return;
        }
        let i = 0;
        for (let [key, value] of modules) {
          if(key != 'map' && key != 'scenarios') {
            this.routes.push(value);
            if (value.default == true) this.defaultRoute = '/' + value.type + '/' + i;
            i++;
          }else if (value.default == true) this.defaultRoute = '/' + key + '/';
          
        }
      },
        },
        getters:{
          getPopularityPoi: (state) => () => {
            const sortedArray: [string, number][] = Object.entries(state.popularity);
            sortedArray.sort((a, b) => b[1] - a[1]);
            return sortedArray;
          },
          returnRepoPath: (state) => (path:string) => {
            return state.publicUrl + 'repo/' + state.destinationId + '/' + path;
          },
        }
  });

  