<template>
       <div class="container" :style="{ backgroundImage: `url(${backgroundImage})` }">
        <div><router-link :to="'/'">Nazaj</router-link></div>
        {{useMapStoreData().routes[id]}}
        <video :src=video></video>
    </div>
</template>
<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useMapStoreData } from "@/stores/index";
import { useRoute } from 'vue-router';

const { t } = useI18n();
const route = useRoute();
const id = computed(() => route.params.id);

const backgroundImage = computed(() => {
    console.log("useMapStoreData().routes[id]: ",useMapStoreData().routes[id]);
    return useMapStoreData().routes[id.value].thumb.hash + "." + useMapStoreData().routes[id.value].thumb.ext;
});
</script>
<style scoped="scss">
.container {
    width:100vw;
    height:100vh;
    background-size: cover;
    background-position: center;
}
</style>