<template>
    <div class="offline-loaded">
        <svg width="39" height="31" viewBox="0 0 39 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.30859 16.2188C9.12891 15.5156 9.94922 14.9297 10.8867 14.4023L14.1094 16.9219C12.9375 17.4492 11.7656 18.1523 10.7695 19.0312C10.0078 19.7344 8.83594 19.6758 8.13281 18.9141C7.48828 18.0938 7.54688 16.9219 8.30859 16.2188ZM19.8516 21.4336L23.0156 23.8945C23.1328 24.3047 23.1914 24.7148 23.1914 25.1836C23.1914 27.2344 21.5508 28.9336 19.4414 28.9336C17.3906 28.9336 15.6914 27.2344 15.6914 25.1836C15.6914 23.0742 17.3906 21.4336 19.4414 21.4336C19.6172 21.4336 19.7344 21.4336 19.8516 21.4336ZM1.27734 9.94922C1.86328 9.36328 2.50781 8.83594 3.15234 8.36719L6.19922 10.7695C5.4375 11.3555 4.61719 11.9414 3.91406 12.6445C3.15234 13.4062 1.98047 13.3477 1.21875 12.5859C0.515625 11.8828 0.574219 10.6523 1.27734 9.94922ZM22.0781 16.043L37.6641 28.2891C38.3086 28.7578 38.4258 29.6367 37.8984 30.2227C37.4297 30.8672 36.5508 30.9844 35.9648 30.457L1.27734 3.26953C0.632812 2.80078 0.515625 1.92188 1.04297 1.33594C1.27734 0.984375 1.6875 0.75 2.15625 0.75C2.44922 0.75 2.74219 0.867188 2.97656 1.10156L8.25 5.20312C11.707 3.5625 15.5156 2.625 19.4414 2.625C26.2969 2.625 32.7422 5.26172 37.6055 9.94922C38.3086 10.6523 38.3672 11.8828 37.6641 12.5859C37.2539 12.9961 36.7852 13.1719 36.3164 13.1719C35.8477 13.1719 35.3789 12.9961 34.9688 12.6445C30.8086 8.60156 25.2422 6.375 19.4414 6.375C16.7461 6.375 14.1094 6.90234 11.6484 7.83984L17.2148 12.1758C17.918 12.1172 18.6797 12 19.4414 12C23.6016 12 27.5273 13.5234 30.6328 16.2188C31.3945 16.9219 31.4531 18.0938 30.8086 18.9141C30.3984 19.3242 29.9297 19.5 29.4023 19.5C28.9336 19.5 28.5234 19.3828 28.1719 19.0312C26.4141 17.5078 24.3047 16.5117 22.0781 16.043Z" fill="#2E3D45"/>
        </svg>

        You are offline! Data might be incomplete. Try reconnecting to the internet.
    </div>
</template>
<script setup>
</script>
<style scoped lang="scss">
@import '../assets/style/mixins.scss';
.offline-loaded {
    position: absolute;
    bottom:0;
    background-color: #fff;
    padding:get-vw(20px) get-vw(50px);
    border-radius: 0px get-vw(50px) get-vw(50px) 0px;
    font-size: get-vw(30px);
    z-index: 99999;
    svg {
        margin-bottom: get-vw(-10px);
        margin-right: get-vw(30px);
    }
}
</style>