<template>
    <div class="circular-progress">
      <flagLoader></flagLoader>
      <svg viewBox="0 0 100 100">
        <circle
          class="progress-background"
          cx="50"
          cy="50"
          :r="radius"
          fill="none"
          :stroke="backgroundColor"
          :stroke-width="strokeWidth"
        />
        <circle
          class="progress-bar"
          cx="50"
          cy="50"
          :r="radius"
          fill="none"
          :stroke="color"
          :stroke-width="strokeWidth"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="dashOffset"
        />
        <text
          x="50"
          y="50"
          text-anchor="middle"
          dominant-baseline="central"
          :font-size="fontSize"
        >
        </text>
      </svg>
    </div>
  </template>
  
  <script setup>
  import { computed } from 'vue'
  import flagLoader from '@/components/FlagLoader.vue'
  
  const props = defineProps({
    progress: {
      type: Number,
      default: 0,
      validator: (value) => value >= 0 && value <= 100
    },
    size: {
      type: Number,
      default: 100
    },
    color: {
      type: String,
      default: '#3498db'
    },
    backgroundColor: {
      type: String,
      default: 'transparent'
    },
    strokeWidth: {
      type: Number,
      default: 2
    }
  })
  
  const radius = computed(() => 50 - props.strokeWidth / 2)
  const circumference = computed(() => 2 * Math.PI * radius.value)
  const dashOffset = computed(() => 
    circumference.value * (1 - props.progress / 100)
  )
  const fontSize = computed(() => props.size / 4)
  </script>
  
  <style scoped lang="scss">
  .circular-progress {
    width: 15vw;
    height: 15vw;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      rotate:180deg;
    }
  }
  
  .progress-bar {
    transition: stroke-dashoffset 0.5s ease;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  </style>