<template>
  <div class="side-poisfilter">
    <template  v-for="([key, value], index) of Array.from(categoryHierarchy)">
    <div v-if="mapStore.poiCategories.get(key).number != 0" class="categories-types"
      :class="['category-' + key, showAllFilters == key ? 'active' : 'inactive'],areAllFiltersDisabled(key)" 
      @click="openFilter(key, $event)"
      >
      <div class="main-category">
        <div class="icon-filter">
          <img :src="allFilters.get(key).icon" alt="">
          <span class="name">{{ showCategoryName(key) }}</span>
        </div>
      </div>
      <div class="main-number">{{ filterNumbers(key) }}</div>
      <div v-for="(subcategories, index) in value" class="sub-category">
        <div 
          :class="getFilterActivity(subcategories)"  
          v-if="allFilters.get(subcategories).number > 0" 
          @click="filterId(subcategories)"
        >
          <div class="icon-filter" :style="{'transition-delay': `${(index/1.5) * 0.1}s`}" :class="index === value.length - 1 ? 'last' : ''">
            <div class="img-holder">
              <img :src="allFilters.get(subcategories).icon">
            </div>
            <span class="name">{{ showCategoryName(subcategories) }}</span>
          </div>
        </div>
        <div @click="useCategoriesStore().toggleMainCategory(key)" v-if="index === value.length - 1">
          <div class="icon-filter all">
            <div class="img-holder">
              <img :src="allFilters.get(key).icon">
            </div>
            <span class="name">{{ t('filter.all') + ' ' + showCategoryName(key) }}</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  <div v-if="useEventStore().validEventsIds.length > 0" @click="toggleEvents" class="event-filter categories-types" :class="[useEventStore().showEvents ? 'activated' : 'inactive gray']"> 
    <div class="main-number">{{ useEventStore().eventNumberFilter }}</div>
    <div class="main-category">
      <div class="icon-filter">
        <img :src="timeIcon" alt="">
      </div>
    </div>
  </div> 
    <div class="reset-filters">
        <div class="icon-filter">
          <span>
            <img :src="resetIcon" @click="resetFilters">
          </span>
        </div>
      </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watchEffect } from 'vue';
import { useCategoriesStore, useMapStoreData,useEventStore,useSetMap } from '@/stores/index'
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n'
import resetIcon from '../assets/reset-icon.svg';
import timeIcon from '@/assets/time-icon.svg'

const mapStore = useCategoriesStore();
const mapStoreData = useMapStoreData();
let allFilters = ref(mapStore.poiCategories);
let categoryHierarchy = ref(mapStore.categoryHierarchy);
let showAllFilters = ref();
const currentRoute = useRoute();
const { t } = useI18n();
const eventLenght = ref(0);

onMounted(() => {
  console.log("useEventStore().validEventsIds.length: ",useEventStore().validEventsIds.length);
  allFilters.value = mapStore.poiCategories;
});

const filterId = (filterId) => {
  mapStore.setFilterCategories(filterId);
};

const resetFilters = () => {
  mapStore.resetFilters();
  if(!useEventStore().showEvents) {
    toggleEvents();
  }
  showAllFilters.value = null;
}

const openFilter = (key, event) => {
  if (event.target.classList.contains('icon-filter')) {
    showAllFilters.value = showAllFilters.value === key ? null : key;
  }
};

const toggleEvents = () => {
  useEventStore().showEvents = !useEventStore().showEvents;
  useSetMap().returnFilteredMarkersMap();
};

const showCategoryName = (subcategories) => {
  const object = mapStoreData.dataSelectors.categories.find(item => item.id == subcategories);

  if (object.locale != undefined) {
    return object.locale[mapStoreData.currentLanguage];
  } else {
    return "No local";
  }
};

const getCurrentPage = () => {
  return currentRoute.path;
};

const getFilterActivity = (filterId) => {
  if (mapStore.activeFilters.includes(filterId)) {
    return 'active';
  } else {
    return 'inactive';
  }
};

const areAllFiltersDisabled = (key) => {
  if(mapStore.activeFilters.length == 0) {
    return 'colored';
  };

  let allIncluded = categoryHierarchy.value.get(key).every(filter => mapStore.activeFilters.includes(filter));
  let someIncluded = categoryHierarchy.value.get(key).some(filter => mapStore.activeFilters.includes(filter));
  let noneIncluded = categoryHierarchy.value.get(key).every(filter => !mapStore.activeFilters.includes(filter));
  
  if(allIncluded) {
    console.log('All filters are active');
    return 'colored';
  } else if(someIncluded) {
    console.log('Some filters are active');
    return 'colored';
  } else if(noneIncluded) {
    console.log('No filters are active');
    return 'gray';
  } else {
    console.log('Unexpected condition');
  }
};


const filterNumbers = (filterId) => {
  let mainnumber = mapStore.poiCategories.get(filterId).number;
  mapStore.categoryHierarchy.get(filterId).forEach((value, key) => {
    let subCategoriesNum = mapStore.poiCategories.get(value).number;
    if(!mapStore.activeFilters.includes(value)) {
      mainnumber = mainnumber - subCategoriesNum;
    }
  });
  return mainnumber;
}
watchEffect(() => {
  if (!mapStoreData.showMap && getCurrentPage() == '/') {
    showAllFilters.value = null;
  }
  //eventLenght.value = useEventStore().validEventsIds.length;
});

</script>
<style scoped lang="scss">
@import '../assets/style/mixins.scss';
@import '../assets/style/components/poisFilter.scss';
</style>