import { defineStore } from 'pinia';

export const usePedestrianStore = defineStore('pedestrianStore', {
        state: () => ({
            all: 0 as number,
            year: 0 as number,
            month: 0 as number,
            //week: 0 as number,
            today: 0 as number,
            last: '' as string,
            loaded: false as boolean,
            isRefreshing: false as boolean
        }),
        actions: {
            async refresh(interval:number) {
                let executionStart = this.getMiliseconds();
                //temp samo za enkratno uporabo, odtrsni on naslednjem deployu
                let isReset = localStorage.getItem('pedestrian_reseted12');
                if (isReset == null) {
                    localStorage.removeItem('pedestrian');
                    localStorage.setItem('pedestrian_reseted12', 'ok');
                    console.log('pedestrian reset5');
                }
                
                if(!this.loaded){ 
                    this.load();
                }
                const zeroDate = "2024-08-22T23:00:00.000Z";
                let keepAll = true;
                let keepYear = true;
                let keepMonth = true;
                //let keepWeek = true;
                let keepDay = false;
                let newDay = false;
                let blankValue = false;
                
                if(this.last == ''){ 
                    this.last = zeroDate;
                    keepAll = false;
                    keepYear = false;
                    keepMonth = false;
                    keepDay = false;
                    newDay = true;
                    blankValue = true;
                }
                const now =  new Date();
                let secureEnd = now;
                secureEnd.setHours(secureEnd.getHours() + 3);
                

                const lastDate = new Date(this.last)
                console.log('pede start end ' + lastDate.toISOString() + '   '+ now.toISOString());
                if(now.getFullYear() != lastDate.getFullYear()){ 
                    keepYear = false;
                    keepMonth = false;
                    keepDay = false;
                    newDay = true;
                }
                else if(now.getMonth() != lastDate.getMonth()){ 
                    keepMonth = false;
                    keepDay = false;
                    newDay = true;
                }
                else if(now.getDate() != lastDate.getDate()){
                    keepDay = false;
                    newDay = true;
                }
                
                //redno dnevnoi
                let start_date = this.last;

                if(!keepDay){ 
                    start_date = now.getFullYear() +'-'+('0' + (now.getMonth()+1)).slice(-2)+'-'+('0' + now.getDate()).slice(-2)+"T00:00:00.000Z";
                    //this.today = 0;
                }
                //console.log('pede getting day ' + start_date + '   '+ now.toISOString());
                let data = await this.getData(start_date, secureEnd.toISOString());
                let diff = this.countPedestrians(data);
                let cnt = this.getSum(diff);

                if(cnt > 0) this.today = cnt;
                //mesec

                let stored:any = this.getStored();
        
                if(keepMonth && !newDay){
                    this.month = stored.month + this.today;
                    //console.log('pede keeping month' );
                }else{
                    
                    start_date = now.getFullYear()+'-'+('0' + (now.getMonth()+1)).slice(-2)+"-01T00:00:00.000Z";
                    if(new Date(zeroDate).getTime() > new Date(start_date).getTime() ) start_date = zeroDate;
                    //console.log('pede zero:' + new Date(zeroDate).getTime() + '  zcetkmesca  '+ new Date(start_date).getTime());
                    //console.log('pede getting monuh' + start_date + '   '+ now.toISOString());
                    data = await this.getData(start_date, secureEnd.toISOString());
                    diff = this.countPedestrians(data);
                    let cntMonth = this.getSum(diff);
                    if(cntMonth > 0) this.month = cntMonth;
                }
                //leto
                if(keepYear && !newDay){
                    this.year =stored.year + this.today;
                }else{
                    
                    start_date = now.getFullYear()+"-01-01T00:00:00.000Z";
                    if(new Date(zeroDate).getTime() > new Date(start_date).getTime() ) start_date = zeroDate;
                    //console.log('pede getting year'+ start_date + '   '+ now.toISOString());
                    data = await this.getData(start_date, secureEnd.toISOString());
                    diff = this.countPedestrians(data);
                    let cntYear = this.getSum(diff);
                    if(cntYear > 0) this.year = cntYear;
                }
                if(keepAll && !newDay){
                    this.all = stored.all + this.today;
                }else{
                    //console.log('pede getting all');
                    data = await this.getData(zeroDate, secureEnd.toISOString());
                    diff = this.countPedestrians(data);
                    let cntAll = this.getSum(diff);
                    if(cntAll > 0) this.all = cntAll;
                }
                this.last = now.toISOString();

                if(newDay){ 
                    let toStore = {all:  this.all, year: this.year, month: this.month, today: this.today, last: this.last}
                    //todo preverit
                    if(true ){
                        toStore.all -= cnt;
                        toStore.year -= cnt;
                        toStore.month -= cnt;
                    }
                    console.log('pede new pedestrian', toStore)
                    localStorage.setItem('pedestrian', JSON.stringify(toStore));
                }
                
                let executionEnd = this.getMiliseconds();
                
                if(interval > -1){
                    let remainingTime = interval - executionEnd + executionStart;
                    if (remainingTime < 0) remainingTime = 10000;
                    console.log('remainingTime' + remainingTime); 
                    setTimeout(() => {
                        this.refresh(interval);
                    }, remainingTime);
                }



            },
            getMiliseconds (){
                return Date.now();
                if(window.performance){
                    return window.performance.now();
                }else{
                    return Date.now();
                }
            },
            
            getSum(diff: any){
                //todo, glede na to kaj se prikazuje
                let cnt = 0;
                //po novem gledamo samo v eno stran
                //if(diff != null)  cnt = parseInt(diff.ltr) + parseInt(diff.rtl)
                if(diff != null)  cnt = parseInt(diff.rtl)
                return cnt;
            },

            countPedestrians(data: any){
                if(!Array.isArray(data)){
                    console.log('pedestrian data not array');
                    return null;
                }
                let result = {ltr: 0, rtl: 0};
                let payload;
                data.forEach((element) => {
                    payload = JSON.parse(element.payload);
                    result.ltr += parseInt(payload.LTR);
                    result.rtl += parseInt(payload.RTL);
                    //if(parseInt(payload.LTR) > 1000) console.log('pede to big', element)
                    //if(parseInt(payload.RTL) > 1000) console.log('pede to big', element)
                });
                return result;
            },

            load() {
                let json = localStorage.getItem('pedestrian');
                if (json !== null) {
                    let data = JSON.parse(json);
                    this.last  = data.last;
                    this.all  = data.all;
                    this.year  = data.year;
                    this.month  = data.month;
                    this.today  = data.today;
                }
                else{
                    this.last  = '';
                    this.all  = 0;
                    this.year  = 0;
                    this.month  = 0;
                    this.today  = 0;
                }
                this.loaded = true;        	
            },
            getStored() {
                let json = localStorage.getItem('pedestrian');
                let stored:Object = {};
                if (json !== null) {
                    let data = JSON.parse(json);
                    stored = {all: data.all, year: data.year, month: data.month, today: data.today}
                };
                return stored;    	
            },

            
            async getData(start_date: string, end_date: string){
                const myHeaders = new Headers();
                myHeaders.append("X-API-Key", "Artrebel9:14a551015b1f247b15819f649cf9eb9c9ee2ad011bd814bc5efa158df7440a3d");
                myHeaders.append("Content-Type", "application/json");
                
                const raw = JSON.stringify({
                  //"start_date": "2024-08-19T23:00:00.000Z",
                  //"end_date": "2024-08-20T23:00:00.000Z",
                  "start_date":start_date,
                  "end_date": end_date,
                  "limit_result": 100000000000,
                  "metadata": false,
                  "order_by_captured": true,
                  "payload_id": "323334306A306817",
                  "reduce_result": 0,
                  "request_payload_transmission": false
                });
                
                const requestOptions : RequestInit = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };
                let responseText = null;
                try{
                    let response = await fetch("https://api.senzemo.com/telemetry/resource", requestOptions);
                    responseText = await response.text(); 
                }catch(error){
                    console.error(error);
                    return false
                }
                return JSON.parse(responseText);
            },
            
           
        },    
    })

