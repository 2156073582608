const DB_NAME = 'imagesdb';
const DB_VERSION = 1;
let DB;

export default {

	init(){
		this.handlePermission();
		this.setPersistent();
	},

	async getDb() {


		return new Promise((resolve, reject) => {

			if(DB) { return resolve(DB); }
			//console.log('OPENING DB', DB);
			let request = window.indexedDB.open(DB_NAME, DB_VERSION);
			
			request.onerror = e => {
				console.log('Error opening db', e);
				reject('Error');
			};
	
			request.onsuccess = e => {
				DB = e.target.result;
				resolve(DB);
			};
			
			request.onupgradeneeded = e => {
				console.log('onupgradeneeded');
				let db = e.target.result;
				db.createObjectStore("markers",{});
			};
		});
	},
	async deleteData(key) {

		let db = await this.getDb();

		return new Promise(resolve => {

			let trans = db.transaction(['markers'],'readwrite');
			trans.oncomplete = () => {
				resolve();
			};

			let store = trans.objectStore('markers');
			store.delete(key);
		});	
	},
    async getData(key) {

		let db = await this.getDb();

		return new Promise(resolve => {

			let trans = db.transaction(['markers'],'readonly');
			trans.oncomplete = () => {
				//console.log('kurac resolvamo'+ key, marker );
				resolve(data);
			};
			
			let store = trans.objectStore('markers');
			let data = null;
			
			store.get(key).onsuccess = e => {
				if(e.target.result) data = e.target.result;
				//console.log('kurac prebrali'+ key );
			};

		});
	},
    //ne rabmo sam za muster kursorjev
	async getCats() {

		let db = await this.getDb();

		return new Promise(resolve => {

			let trans = db.transaction(['markers'],'readonly');
			trans.oncomplete = () => {
				resolve(cats);
			};
			
			let store = trans.objectStore('markers');
			let cats = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					cats.push(cursor.value)
					cursor.continue();
				}
			};

		});
	},

	async saveData(data, key) {

		let db = await this.getDb();

        //console.log('storamo '+key)

		return new Promise(resolve => {

			let trans = db.transaction(['markers'],'readwrite');
			trans.oncomplete = () => {
                //console.log('posejvali '+key)
				resolve();
			};

			let store = trans.objectStore('markers');
			store.put(data, key);

		});
	
	},
	 handlePermission() {
		return navigator.permissions
				.query({name:'notifications'})
				.then(this.permissionQuery)
				.catch(this.permissionError);
	},
	
	 permissionQuery(result) {
		console.debug({result});
		var newPrompt;
	
		if (result.state == 'granted') {
			console.log('notafication permission granted');
	
		} else if (result.state == 'prompt') {
			console.log('notafication permission asked');
			newPrompt = Notification.requestPermission();
	
		} else if (result.state == 'denied') {
			console.log('notafication permission denied');
			newPrompt = Notification.requestPermission();
		}
	
		result.onchange = () => console.debug({updatedPermission: result});
	
		return newPrompt || result;
	},
	permissionError(error){
		console.log('notafication permission error', error);
	},
	setPersistent(){
		if (navigator.storage && navigator.storage.persist) {
			navigator.storage.persist().then(persistent => {
			  if (persistent) {
				console.log("Storage will not be cleared except by explicit user action");
			  } else {
				console.warn("Storage may be cleared by the UA under storage pressure.");
				navigator.storage.persist().then((fullfiled) => {
					console.log('storage.persist: '+fullfiled)
				})
			  }
			});
		}
	},

}