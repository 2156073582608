import { defineStore } from 'pinia';
import { useCategoriesStore } from '@/stores/index'

export const useSearchData = defineStore('SearchData', {
    state: () => ({
        //searchActive: false as boolean,
        searchFilteredItems: [] as Array<any>,
      }),
      actions: {
        searchFilter(id:any,categories:Array<string>) {
          if(useCategoriesStore().filterMarkersByCategory(categories)) {
            return useSearchData().searchFilteredItems.some(searchItem => searchItem.properties.id == id);
          }else {
            return false;
          }
        }
      }
});