import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MapView from '../views/MapView.vue'
import ScenariosView from '../views/ScenariosView.vue'
import VideoView from '../views/VideoView.vue'
import GalleryView from '../views/GalleryView.vue'
import IFrame from '../views/IFrame.vue'
import TextView from '../views/TextView.vue'
import Bats from '../components/podcetrtek/Bats.vue'
import wishingWell from '../views/WishingWellView.vue'
import PedestrianCounterView from '../components/podcetrtek/PedestrianCounterView.vue'

let originalRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/map',
    name: 'map',
    component: MapView
  },
  {
    path: '/scenarios',
    name: 'scenarios',
    component: ScenariosView
  },
  {
    path: '/scenario/:id',
    name: 'scenario',
    component: ScenariosView
  },
  {
    path: '/video/:id',
    name: 'video',
    component: VideoView
  },
  {
    path: '/gallery/:id',
    name: 'gallery',
    component: GalleryView
  },
  {
    path: '/iframe/:id',
    name: 'iframe',
    component: IFrame
  },
  {
    path: '/text/:id',
    name: 'text',
    component: TextView
  },
  {
    path: '/bat/:id',
    name: 'bats',
    component: Bats
  },
  {
    path: '/wishingWell',
    name: 'wishingWell',
    component: wishingWell
  },
  {
    path: '/pedestrianCounter/:id',
    name: 'pedestrianCounter',
    component: PedestrianCounterView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: originalRoutes
})

export default router