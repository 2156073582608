<template>
      <div class="events">
          <div class="">
            <div @click="goToActivePoi(event.properties.id)" class="swiper-slide" v-for="(event, index) in props.activeEvents" :key="index">
             
              <img v-if="pathRoute" class="timeIcon" :src="timeIcon" alt="">
              <img v-if="!pathRoute" class="timeIcon-right" :src="rightArrowSlider" alt="">
              <div class="info">
                <div class="event-title">{{ event.properties.title }}</div>
                <div class="event-date">
                  {{ new Date(event.properties.start.split(' ')[0]).toLocaleDateString('en-GB') }} 
                  - 
                  {{ new Date(event.properties.end.split(' ')[0]).toLocaleDateString('en-GB') }}
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-next-custom" v-if="activeEvents.length > 1">
            <img :src="rightArrowSlider" alt="" />
          </div>
        </div>
</template>    
<script setup>
import {ref,nextTick,onMounted,computed} from "vue";
import {useCategoriesStore,useMapStoreData} from '@/stores/index'
import Swiper from "swiper";
import "swiper/css";
import rightArrowSlider from '@/assets/right.svg'
import timeIcon from '@/assets/time-icon.svg'
import { useRoute } from 'vue-router';

const props = defineProps({
    activeEvents: Array,
});
const emit = defineEmits(['goToActivePoi']);
let swiperEvent = ref(null);
const route = useRoute();
const pathRoute = computed(() => route && route.path == '/scenarios/');

onMounted (() => {
  let swiperOptionsEvent = {
    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next-custom",
    },
    loop:true
  };

  nextTick(() => {
    if(document.querySelector('.events.swiper') != undefined) {
      //swiperEvent.value = new Swiper(".events.swiper", swiperOptionsEvent);
    }
  });
});

function goToActivePoi(id) {
  emit('goToActivePoi', id);
  useMapStoreData().poiPopularity(id);
  useMapStoreData().setActivePoi(id);
}
</script>
<style scoped lang="scss">
@import '../assets/style/mixins.scss';
.events {
    color:#fff;
    overflow: hidden;
    //display: flex;
    border-radius: 0 0 get-vw(20px) get-vw(20px);
    position: relative;
    
    .swiper-button-next-custom {
        position: relative;
        z-index: 999;
        margin-left: get-vw(-40px);

        img {
            width:get-vw(25px);
            position: absolute;
            top:50%;
            transform:translateY(-50%);
        }
    }
    .pagination-container {
        position: relative;
    }
    .swiper-slide {
        padding:get-vw(15px);
        display: flex;
        background: linear-gradient(180deg, #D6779D 0%, #BD567B 100%); 
        border-radius: 2px;
        .timeIcon {
            width:get-vw(40px);
            margin-right:get-vw(25px);
        }
        .timeIcon-right {
            width:get-vw(22px);
            margin-right:get-vw(25px);
        }
        .info {
            width:70%;
            .event-title {
                font-size: get-vw(20px);
                margin-bottom: get-vw(5px);
                font-weight: 700;
            }
            .event-date {
                font-size: get-vw(15px);
                line-height: get-vw(20px);
            }
        }
    }
}
</style>