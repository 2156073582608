import { defineStore } from 'pinia';
import {Analitika} from '@/utils/analitika'
import { useMapStoreData } from '@/stores/index'

export const useAnalyticsStore = defineStore('analyticsStore', {
    state: () => ({
        analitika: null as any,
        needRestart: false as boolean
    }),
    actions: {
        setupAnalytics() {
            if(this.analitika == null) { 
                this.analitika = new Analitika({api: "https://api.simpl.guide/json.php",
                hermes_client: "http://localhost:5000", destination: useMapStoreData().destinationId,
                url: window.location.href
            });
            }
        },
        startSession() {
            this.analitika.startSession();
        },
        stopSession() {
            this.analitika.closeSession();
        },
        send(event:any,data:any) {
            this.analitika.send(event,data);
        },
        startInteract(data:any) {
            this.analitika.start_interact(data);
        },
        stopInteract() {
            this.analitika.stop_interact();
        },
        appReport(callback: any) {
            this.analitika.appReport(callback);
        },
        setStatusData(field: string, value: any) {
            if(this.analitika == null) return ;
            this.analitika.setStatusData(field, value);
        },
        report_js_error(message: string, source: any, lineno: any, colno: any, error: any, callback: any) {
            if(this.analitika == null) return ;
            this.analitika.report_js_error(message, source, lineno, colno, error, useMapStoreData().destinationId, callback);
        },
        getMemory() {
            return this.analitika.getMemory();
        }
    }
});