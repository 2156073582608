<template>
  <div>
    <div v-if="useSetMap().mapLoader && useMapStoreData().isOnline" class="loading">
      <div class="container">
        <div class="loading">
      <div class="container">
        <CircularProgress :progress="loaderLength" color="#2E3D45"></CircularProgress>
      </div>
   </div>
      </div>
   </div>
<Transition name="fade">
<div>
  <Header v-once :setupHeader=setupDataHeader @changeOrderFilter="changeOrderDataFilter">
  </Header>
  <PoiPresentation 
  v-if="activePoiPresentation" 
  @hideCloseBtnPoiPresentation="closedPoiPresentation" 
  uniqueId="scenariosPoiPresentation" 
  :activePoi="activePoiPresentation">
  </PoiPresentation>
  <div class="scenarios">
    <path d="M42.8906 14.75C44.0039 14.75 45 15.4531 45 16.5664V34.2617C45 34.9648 44.5312 35.6094 43.7109 35.9023C41.1914 36.8984 39.082 37.25 37.207 37.25C32.8711 37.25 29.8828 35.4336 25.5469 35.4336C23.4375 35.4336 20.918 35.9023 17.8125 37.25V43.3438C17.8125 44.1641 17.168 44.75 16.4062 44.75C15.5859 44.75 15 44.1641 15 43.3438V16.1562C15 15.3945 15.5859 14.75 16.4062 14.75C17.168 14.75 17.8125 15.3945 17.8125 16.1562V16.6836C21.2695 15.2773 23.7891 14.8086 25.8398 14.8086C29.8828 14.8086 31.9336 16.6836 35.4492 16.6836C37.0898 16.6836 39.082 16.2734 41.7188 15.043C42.1289 14.8672 42.4805 14.75 42.8906 14.75ZM42.1875 33.5V17.9141C39.668 18.9688 37.5 19.4961 35.4492 19.4961C33.2812 19.4961 31.582 18.9102 30.1172 18.4414C28.7109 18.0312 27.4805 17.6211 25.8398 17.6211C23.9062 17.6211 21.6211 18.1484 18.8672 19.2617L17.8125 19.7305V34.2031C20.5078 33.1484 23.0859 32.6211 25.5469 32.6211C28.0664 32.6211 30.1758 33.1484 32.0508 33.6172C33.8086 34.0273 35.3906 34.4375 37.207 34.4375C38.7305 34.4375 40.3711 34.1445 42.1875 33.5Z" fill="#2E3D45"></path>

    <div class="scenarios-container">
      <div v-if="route.name != 'scenarios'" class="sheader">
        <h3  class="title">
            <img @click="goHome()" :src="leftArrow" alt="" />
            {{ t(title) }}
        </h3>
        <img :src="bline" alt="" />
      </div>
      <div class="scroller" ref="scrollContainer">
        <template v-if="displayedMarkers.length > 0">
          <ScenariosCard v-for="(item, index) in displayedMarkers"
            :key="item.value.properties.id"
            @click="goToActivePoi(item.value)" 
            :ref="index === displayedMarkers.length - 1 ? setObserver : undefined"
            :item="item"
            :style="{ order: '-' + getOrder(item.value.properties.id) }"
            v-memo="[item.value.properties.id, getOrder(item.value.properties.id)]">
          </ScenariosCard>
        </template>
        <div class="no-results" v-else>
          <h1>{{ t('general.noresult') }}</h1>
        </div>
      </div>
    </div>
  </div>
</div>

</Transition>
</div>
</template>
<script setup>

import { ref, onBeforeMount, computed,onBeforeUnmount,onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'
import CircularProgress from '@/components/CircularProgress.vue'

//pinia
import { useCategoriesStore,useSetMap,useSearchData,useScenariosStore,useEventStore } from '@/stores/index'
//components
import Header from '@/components/Header.vue'
import PoiPresentation from '@/components/PoiPresentation.vue'
import ScenariosCard from '@/components/ScenariosCard.vue'
import leftArrow from "@/assets/presentation/left-arrow.svg";
import bline from "@/assets/bline.svg";
import loader from '@/assets/LOAD2.png'
import {useMapStoreData,useMarkersStore} from '@/stores/index'



const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const routeid = computed(() => route.params.id);
const title = computed(() => useMapStoreData().routes[routeid.value].title);
const sortByPopularityData = ref('');
const lastElement = ref(null);
const mapStore = useMapStoreData();
const markersStore = useMarkersStore();

let itemCount = ref(16); 
const scrollContainer = ref(null);
const preventClick = ref(true);
const activePoiPresentation = ref(null);
const loaderLength = computed(() => {
  return ((markersStore.imagesLoaded + markersStore.countCategoriesMarkers) * 100) / (mapStore.allMarkersNumber + 80);
});

let setupDataHeader = {
  centerIcon: false,
  orderFilter: true,
  search: true,
  backArrow: false
}

let filterOrder = ref('Alphabetical');

onBeforeMount(() => {
  useMapStoreData().setPopularityPoi();
});

onMounted(() => {
  useMapStoreData().hideMap = true;
  setTimeout(() => {
    preventClick.value = false;
  }, 300);
});

function goToActivePoi(item) {
  if(preventClick.value) return;
  let id = item.properties.id;
  activePoiPresentation.value = id;
  useMapStoreData().poiPopularity(id);
  useMapStoreData().setActivePoi(id);
}

function goHome(){
  router.push('/');
}
async function closedPoiPresentation() {
  setTimeout(() => {
    activePoiPresentation.value = null;
  }, 500); //
  //useMapStoreData().setActivePoi(null);
}

function changeOrderDataFilter(event) {
  let order = event.order;
  filterOrder.value = order;
  if (order == 'Most popular') {
    const popularityPoi = useMapStoreData().getPopularityPoi();
    sortByPopularityData.value = popularityPoi;
  }
}

function getOrder(id) {
  if (filterOrder.value == 'Most popular') {
    const sortedItem = sortByPopularityData.value.find(item => item[0] == id);
    return sortedItem ? sortedItem[1] : '';
  } else {
    return '0';
  }
}

// Computed property to display a subset of sortedMarkers
const displayedMarkers = computed(() => {
  return filterMarkers.value.slice(0, itemCount.value);
});




const filterMarkers = computed(() => {
  let invalidEvent = useEventStore().invalidEventsIds;

  if(route.name == 'scenario'){
    const data = useScenariosStore().sortedMarkers.filter(item => filterMarkersByTags(item.value.properties.tags, useMapStoreData().routes[routeid.value].content.tags));
    return data;
  }
  else if(route.name != 'scenarios'){

    if(!useEventStore().showEvents) {
      let allEvent = useEventStore().allEventsId;
      let result = data.filter((item) => !allEvent.includes(item.key));
      useEventStore().eventNumberFilter = 0;
      return result;
    }
    let dataFilteredEvents = data.filter((item) => !invalidEvent.includes(item.key));
    let countEvent = dataFilteredEvents.filter((itemmarker) => itemmarker.value.properties.type == 'event').length;
    useEventStore().eventNumberFilter = countEvent;
    return dataFilteredEvents;

  }
  else if(useSearchData().searchFilteredItems.length > 0) {
    const data = useScenariosStore().sortedMarkers.filter(item => useSearchData().searchFilter(item.key,item.value.properties.categories));
    if(!useEventStore().showEvents) {
      let allEvent = useEventStore().allEventsId;
      let result = data.filter((item) => !allEvent.includes(item.key));
      useEventStore().eventNumberFilter = 0;
      return result;
    }
    let dataFilteredEvents = data.filter((item) => !invalidEvent.includes(item.key));
    let countEvent = dataFilteredEvents.filter((itemmarker) => itemmarker.value.properties.type == 'event').length;
    useEventStore().eventNumberFilter = countEvent;
    return dataFilteredEvents;
  }else {
    const data = useScenariosStore().sortedMarkers.filter(item => useCategoriesStore().filterMarkersByCategory(item.value.properties.categories));
    if(!useEventStore().showEvents) {
      let allEvent = useEventStore().allEventsId;
      let result = data.filter((item) => !allEvent.includes(item.key));
      useEventStore().eventNumberFilter = 0;
      return result;
    }
    let dataFilteredEvents = data.filter((item) => !invalidEvent.includes(item.key));
    let countEvent = dataFilteredEvents.filter((itemmarker) => itemmarker.value.properties.type == 'event').length;
    useEventStore().eventNumberFilter = countEvent;
    return dataFilteredEvents;
  }
});





// Intersection Observer to detect when the last item is visible
let observer = new IntersectionObserver((entries) => {
  if (entries[0].isIntersecting) {
    loadMoreItems();
  }
}, {
  root: scrollContainer.value,
  rootMargin: '0px',
  threshold: 0.25
});

// Set observer on the last element
const setObserver = (el) => {
  if (el) {
    lastElement.value = el.$el || el;
    observer.observe(lastElement.value);
  }
}

onBeforeUnmount(() => {
  //useMapStoreData().hideMap = false;
  if (observer) {
    observer.disconnect();
  }
});

function loadMoreItems() {
  // Implement logic to load more items here
  itemCount.value += 12; // Load 12 more items
  if (itemCount.value >= useScenariosStore().sortedMarkers.length) {
    observer.unobserve(lastElement.value); // Stop observing if all items are loaded
  }
}


function filterMarkersByTags(poiTags, filterTags) {
            //console.log(poiTags)
            return (poiTags != null && poiTags.some(tag => filterTags.includes(tag)));
}

</script>
<style scoped lang="scss">
@import '../assets/style/mixins.scss';
@import '../assets/style/ScenariosView.scss';
.loading {
      position:absolute;
      width:100vw;
      height:100vh;
      background-color: #fff;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      z-index: 2;
      .container {
        width: 10%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
      }
      img {
        width:100%;
        max-width:100%;
        display: block;
        margin:0 auto get-vw(50px) auto;
      }
      h1 {
        color:#000;
      }
    
      .empty-loading-bar {
        border:2px solid #626D73;
        width:100%;
        background-color: #fff;
        height: get-vw(15px);
      }
      .loading-bar {
        margin-top:get-vw(-12px);
        background-color: #626D73;
        height: get-vw(10px);
      }
    }
</style>
  