<template>
    <div :class="[{shortheader:props.setupHeader?.shortHeader}]" class="main-header">
        <div class="container">
            <div class="logo">   
                <img :src="logo" alt="">
            </div>
            <div v-show="props.setupHeader.search" class="search-container" data-cy="search-container">
                <Search v-if="useMarkersStore().mapSeen" v-show="pathRoute || mapStore.showMap" :key="searchRerender"></Search>
                <SortingDropdown v-if="useMarkersStore().mapSeen" v-show="pathRoute && useMapStoreData().activePoi == null"></SortingDropdown>
            </div>
            <div class="menu">
                <div class="language-container">
                    <LanguageChanger v-if="!hideLanguageSelector" uniqueClass="header-lang"></LanguageChanger>
                </div>
                <div class="home-menu-container">
                    <div class="container" @click="hideMap()">
                        <img class="menu-icon" :src="menuIcon" alt="">
                        <img data-cy="home-icon" class="home-icon" :src="homeIcon" alt="">
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref,onMounted,computed } from "vue";
import {useRouter,useRoute } from 'vue-router';
import  logo  from '@/assets/logo.svg'; 
import  homeIcon  from '@/assets/menu/home-icon.svg';
import menuIcon from '@/assets/menu/menu-icon.svg'; 
// @ts-ignore
import { useI18n } from 'vue-i18n'
import {useSetMap,useMapStoreData,useCategoriesStore,useMarkersStore,useFavoritesStore,useSearchData,useScenariosStore} from '@/stores/index'
import LanguageChanger from '@/components/LanguageChanger.vue';
import Search from '@/components/Search.vue';
import SortingDropdown from '@/components/SortingDropdown.vue';


const { locale } = useI18n();

const selectedLanguage = ref('hr');
//const showDropdownOrder = ref(false);
const mapStore = useMapStoreData();
const mapboxStore = useSetMap();
const headerSetup = ref(null);
const hideLanguageSelector = ref(false);
//const selectedOrder = ref('Alphabetical');
const props = defineProps({
    setupHeader: Object,
});
let setData = useCategoriesStore();
const router = useRouter();
const route = useRoute();
const pathRoute = computed(() => route.path == '/scenarios/');
const searchRerender = ref(0);

const emits = defineEmits(['changeOrderFilter','closeMap']);

onMounted(() => {
    headerSetup.value = props.setupHeader.setupDataHeader;
    selectedLanguage.value = mapStore.currentLanguage;
    hideLanguageSelector.value = props.setupHeader.hideLanguage;
});

/*When filters in scenarios*/ 
/*function changeOrder(order) {
    emits('changeOrderFilter', { order });
    selectedOrder.value = order;
    showDropdownOrder.value = false;
}*/
function hideMap() {
    console.log("hide map");
    mapboxStore.centerMapbox();
    emits('closeMap');
    useFavoritesStore().closeFavoritesPanel();
    if(mapStore.showMap) {
        mapStore.showMap = false;
        searchRerender.value++;
    }
    useSearchData().searchFilteredItems = [];
    setData.resetFilters();
    useScenariosStore().populateSortedMarkers(useScenariosStore().defaultSorting.key);
    router.push('/');
    useMapStoreData().activePoi = null;
}
</script>
<style scoped lang="scss">
@import '../assets/style/mixins.scss';
@import '../assets/style/components/header.scss';
</style>