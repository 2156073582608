<template>
  <Transition @after-leave="onAfterLeave">
    <div v-if="activeMarkerId !== null" class="poi-presentation" 
    data-cy="poi-presentation"
    :class="[{favorited:useFavoritesStore().favorites.includes(activeMarkerId)},activeMarkerData.type,props.uniqueId,'presentation-' + categoryStyle]">
    
      <div class="content" :class="{minimizeActive:toggleMinimization}">
        <h3 v-if="activeMarkerData.type != 'event'" class="title">
          <img @click="hidePresentation()" :src="leftArrow" alt="" />
          {{ t(activeMarkerId + '.title') }}
        </h3>
        <div class="info-event-top" v-if="activeMarkerData.type == 'event'">
          <div class="title-holder">
            <img @click="hidePresentation()" :src="leftArrow" alt="" />
           {{ t(activeMarkerId + '.title') }}
         </div>
 
          <div class="date"> {{ t('general.duration') }} {{ eventDateStart}} - {{ eventDateEnd }}</div>
        </div>
        <div class="minimize">
          <div v-if="activeMarkerData.type != 'event'" :style="{ 'background-color': selectedMarkerColor }" class="line"></div>
          <div class="categories">
            <img v-if="activeMarkerData.type == 'event'" class="event-category" :src="timeIcon" alt="">
            <template v-for="(icon, index) in activeMarkerData.categoryIcon">
              <img v-if="index != 0" :class="'category-' + icon.categoryId" :src="icon.img" alt="" />
            </template>
          </div>
          <div class="text-content">
            <div class="description" v-html="t(activeMarkerId + '.description')"></div>
            <div><i v-if="activeMarkerData.address">{{t('general.address') + ': ' + activeMarkerData.address }}</i></div>
            <div><i v-if="activeMarkerData.openingHours">{{ t('general.opening_hours') + ': ' + activeMarkerData.openingHours }}</i></div>
            <div><i v-if="activeMarkerData.international_phone_number"> {{t('general.phone') + ': ' + activeMarkerData.international_phone_number }}</i></div>
            <div><i v-if="activeMarkerData.email">{{t('general.email') + ': ' + activeMarkerData.email }}</i></div>
            <div><i v-if="activeMarkerData.website">{{ t('general.website')  }} {{ activeMarkerData.website }}</i></div>
          </div>
          <EventsSlider v-show="!isEvent" @go-to-active-poi="changeToEvent" :active-events="activeEvents" v-if="activeEvents.length > 0"></EventsSlider>
        </div>
      </div>
      <div class="options">
        <div class="model-icon-container" :class="{active:toggleModel}" @click="showModel" v-if="isModel">
          <modelIcon :isActive="toggleModel" />
        </div>
        <div v-show="useMapStoreData().isOnline" class="map-icon-container">
          <img class="map-icon" @click="handleMapIconClick" :src="mapIcon" alt="">
        </div>
        <div v-if="useFavoritesStore().favorites.length < 10" @click="useFavoritesStore().toggleFavorites(activeMarkerId)" class="favorite-flag-icon">
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="60" height="60" rx="30" fill="white" />
              <path d="M42.8906 14.75C44.0039 14.75 45 15.4531 45 16.5664V34.2617C45 34.9648 44.5312 35.6094 43.7109 35.9023C41.1914 36.8984 39.082 37.25 37.207 37.25C32.8711 37.25 29.8828 35.4336 25.5469 35.4336C23.4375 35.4336 20.918 35.9023 17.8125 37.25V43.3438C17.8125 44.1641 17.168 44.75 16.4062 44.75C15.5859 44.75 15 44.1641 15 43.3438V16.1562C15 15.3945 15.5859 14.75 16.4062 14.75C17.168 14.75 17.8125 15.3945 17.8125 16.1562V16.6836C21.2695 15.2773 23.7891 14.8086 25.8398 14.8086C29.8828 14.8086 31.9336 16.6836 35.4492 16.6836C37.0898 16.6836 39.082 16.2734 41.7188 15.043C42.1289 14.8672 42.4805 14.75 42.8906 14.75ZM42.1875 33.5V17.9141C39.668 18.9688 37.5 19.4961 35.4492 19.4961C33.2812 19.4961 31.582 18.9102 30.1172 18.4414C28.7109 18.0312 27.4805 17.6211 25.8398 17.6211C23.9062 17.6211 21.6211 18.1484 18.8672 19.2617L17.8125 19.7305V34.2031C20.5078 33.1484 23.0859 32.6211 25.5469 32.6211C28.0664 32.6211 30.1758 33.1484 32.0508 33.6172C33.8086 34.0273 35.3906 34.4375 37.207 34.4375C38.7305 34.4375 40.3711 34.1445 42.1875 33.5Z" fill="#2E3D45"/>
            </svg>
       </div>
       <div class="minimize-btn" v-show="showSwiperNav" :class="[{active:toggleMinimization},'style-' + categoryStyle]" @click="toggleMinimization = !toggleMinimization">
        <MinimizeIcon :isActive="toggleMinimization" />
        </div>
      </div>
      <div class="multimedia-slider" :class="{'noImage': !showSwiperNav}">
        <model-viewer v-if="toggleModel" class="modelviewer" :src="modelUrl" alt="A 3D model" ar ar-modes="webxr scene-viewer quick-look" auto-rotate camera-controls></model-viewer>
        <div class="swiper-container" v-show="!toggleModel">
          <div class="swiper-wrapper">
            <div v-for="(slide, index) in activeMarkerData.images" :key="index" class="swiper-slide">
              <div class="background-image" :style="{ 'background-image': 'url(' + slide + ')' }"></div>
            </div>
            <div v-for="(slide, index) in activeMarkerData.videos" :key="index" class="swiper-slide">
              <video muted loop autoplay preload="auto">
                <source :src="slide" type="video/mp4">
              </video>
            </div>
          </div>
          <div class="pagination-container" v-show="showSwiperNav">
            <div class="swiper-button-prev-custom">
              <img :src="leftArrowSlider" alt="" />
            </div>
            <div class="swiper-pagination-custom"></div>
            <div class="swiper-button-next-custom">
              <img :src="rightArrowSlider" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script setup>
import { ref, Transition,nextTick,onMounted } from "vue";
import { useRoute,useRouter } from 'vue-router'
import { useMarkersStore, useCategoriesStore, useMapStoreData,useAnalyticsStore,useFavoritesStore } from "@/stores/index";
import { useI18n } from "vue-i18n";
import MinimizeIcon from "@/components/icons/MinimizeIcon.vue";
import modelIcon from "@/components/icons/modelIcon.vue";
import '@google/model-viewer';
//swiper
import Swiper, { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";

//images
import leftArrowSlider from "@/assets/common/arrow-left.svg";
import rightArrowSlider from "@/assets/common/arrow-right.svg";
import leftArrowWhite from "@/assets/presentation/left-arrow-white.svg";
import leftArrow from "@/assets/presentation/left-arrow.svg";
import mapIcon from '@/assets/presentation/map-icon.svg';
import timeIcon from '@/assets/time-icon.svg'
import EventsSlider from '@/components/EventsSlider.vue'

const activeMarkerData = ref({
  address: "",
  locationData: "",
  openingHours: "",
  contactInformation: "",
  categoryIcon: [],
  images: [],
  videos:[],
  openingHours: "",
  email: "",
  phone: "",
  type: "",
});

const activeMarkerId = ref(null);
const swiper = ref(null);
const showSwiperNav = ref(true);
const { t } = useI18n();
const props = defineProps(["poiPresentationData", "uniqueId","activePoi"]);
const useMarkers = useCategoriesStore();
const markersStore = useMarkersStore();
const mapStoreData = useMapStoreData();
const currentRoute = useRoute();
const router = useRouter();
const selectedMarkerColor = ref();
const toggleMinimization = ref(false);
const categoryStyle = ref();
const isModel = ref(false);
const isEvent = ref(false);
const eventPoiId = ref(null);
const modelUrl = ref(null);
const activeEvents = ref([]);
const toggleModel = ref(false);
const eventDateStart = ref();
const eventDateEnd = ref();
const emits = defineEmits(["hideCloseBtnPoiPresentation","showPoiOnMap"]);


onMounted(() => {
  if (props.activePoi !== null) {
    setPresentationData(mapStoreData.markers[props.activePoi]);
  }
});

Swiper.use([Pagination, Navigation, Autoplay]);

const setPresentationData = (newValue) => {
  if (swiper.value != null) {
    swiper.value.destroy();
  }
  
  const {
    id,
    address,
    locationData,
    openingHours,
    contactInformation,
    email,
    international_phone_number,
    categories,
    type,
    website
  } = newValue.properties;
  selectedMarkerColor.value = useMarkers.returnStartStopColor(categories[0]);
  activeMarkerId.value = id;
  categoryStyle.value = categories[0];
  activeMarkerData.value = {
    address,
    locationData,
    openingHours,
    contactInformation,
    email,
    international_phone_number,
    website,
    images: markersStore.getMarkerImage(id.toString(),'4k'),
    videos: markersStore.getMarkerVideos(id.toString()),
    categoryIcon: categories.map((id) => ({
      img: useMarkers.returnCategoryIcon(id),
      categoryId: id,
    })),
    type,
  };
  
  if(type == 'event') {
    isEvent.value = true;
    eventDateStart.value = new Date(newValue.properties.start.split(' ')[0]).toLocaleDateString('en-GB');
    eventDateEnd.value = new Date(newValue.properties.end.split(' ')[0]).toLocaleDateString('en-GB');
  }

  try {
    isModel.value = newValue.properties.presentation.basic.content.hasOwnProperty("model");

    if(isModel) {
      let files = newValue.properties.presentation.basic.content.model.files[0];
      modelUrl.value = 'repo/' + useMapStoreData().destinationId + '/' + files.hash + "." + files.ext;
    }
  } catch (error) {
    isModel.value = false;
  }

  let swiperOptions = {
    autoplay: {
      delay: 3000,
      disableOnInteraction: true, // stops autoplay when user interacts with the slider
    },
    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom",
    },
    pagination: {
      el: ".swiper-pagination-custom", // This should match the pagination container in your HTML
      clickable: true,
    },
    loop:true
  };

  const isSingleImage = activeMarkerData.value.images.length > 0 || activeMarkerData.value.videos.length > 0
  showSwiperNav.value = isSingleImage;
  const swiperClass = "." + props.uniqueId + " .swiper-container";
  if(newValue.properties.hasOwnProperty('events')) {
    assignEvents(newValue.properties.events);
  }
  useAnalyticsStore().send("poi_click", {poi: id});
  useAnalyticsStore().startInteract(newValue.properties);
  nextTick(() => {
    swiper.value = new Swiper(swiperClass, swiperOptions);
  });
};

function onAfterLeave() {
  resetPresentation();
}

const hidePresentation = () => {
  if(eventPoiId.value != null) {
    setPresentationData(mapStoreData.markers[eventPoiId.value]);
    eventPoiId.value = null;
    isEvent.value = false;
    return;
  }
  if(getCurrentPage() == '/scenarios') {
    mapStoreData.showMap = false;
  }
  useMapStoreData().activePoi = null;
  activeMarkerId.value = null;
  emits("hideCloseBtnPoiPresentation");
};

function changeToEvent(id) {
  if(!isEvent.value) {
    eventPoiId.value = activeMarkerId.value;
    setPresentationData(mapStoreData.markers[id]);
  }
}
const resetPresentation = () => {
    if (swiper.value != null) {
    swiper.value.destroy();
  }
  toggleMinimization.value = false;
  activeMarkerData.value = {
    address: "",
    categories: "",
    locationData: "",
    openingHours: "",
    contactInformation: "",
    categoryIcon: [],
    images: [],
    videos:[],
    openingHours: "",
    email: "",
    international_phone_number: "",
    website: "",
  };
  useAnalyticsStore().stopInteract();
}

const handleMapIconClick = () => {
  mapStoreData.showMap = true;
  useMapStoreData().hideMap = false;
  useMapStoreData().activePoi = activeMarkerId.value;
  router.push('/');
  //emits('showPoiOnMap', activeMarkerId.value);
}

function getCurrentPage() {
    return currentRoute.path;
}

const showModel = () => {
  toggleModel.value = !toggleModel.value
}

const assignEvents = (events) => {
  let currentDateObject = new Date();
  currentDateObject.setHours(0, 0, 0, 0); // Set current date hours to 0
  activeEvents.value = [];
  
  events.forEach(event => {
    let startDate = new Date(mapStoreData.markers[event].properties.start);
    let endDate = new Date(mapStoreData.markers[event].properties.end);
    // Remove the time part of the dates
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    // Check if event is today or in the past but not ended yet or starts within the next month
    if (startDate.getTime() === currentDateObject.getTime() ||
        (startDate.getTime() < currentDateObject.getTime() && endDate.getTime() >= currentDateObject.getTime()) ||
        (startDate.getTime() > currentDateObject.getTime() && startDate.getTime() < currentDateObject.getTime() + 30 * 24 * 60 * 60 * 1000)) {
      activeEvents.value.push(mapStoreData.markers[event]);
    }
  });

  activeEvents.value.sort((a, b) => new Date(a.properties.start).getTime() - new Date(b.properties.start).getTime());
}
</script>
<style scoped lang="scss">
@import "../assets/style/mixins.scss";
@import "../assets/style/components/poiPresentation.scss";

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
