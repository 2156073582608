<template>
        <div class="scenarios-card"
        :class="['poi-' + props.item.value.properties.categories[0], props.item.value.properties.type, 
        props.item.value.properties.id, 
        { favorited: useFavoritesStore().favorites.includes(props.item.value.properties.id) }
        ]" 
          :key="props.item.value.properties.id">
          <div class="flag-favorites" v-if="useFavoritesStore().favorites.length < 10" @click.stop="toggleFavorites(props.item.value.properties.id)">
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="60" height="60" rx="30" fill="white" />
              <path d="M42.8906 14.75C44.0039 14.75 45 15.4531 45 16.5664V34.2617C45 34.9648 44.5312 35.6094 43.7109 35.9023C41.1914 36.8984 39.082 37.25 37.207 37.25C32.8711 37.25 29.8828 35.4336 25.5469 35.4336C23.4375 35.4336 20.918 35.9023 17.8125 37.25V43.3438C17.8125 44.1641 17.168 44.75 16.4062 44.75C15.5859 44.75 15 44.1641 15 43.3438V16.1562C15 15.3945 15.5859 14.75 16.4062 14.75C17.168 14.75 17.8125 15.3945 17.8125 16.1562V16.6836C21.2695 15.2773 23.7891 14.8086 25.8398 14.8086C29.8828 14.8086 31.9336 16.6836 35.4492 16.6836C37.0898 16.6836 39.082 16.2734 41.7188 15.043C42.1289 14.8672 42.4805 14.75 42.8906 14.75ZM42.1875 33.5V17.9141C39.668 18.9688 37.5 19.4961 35.4492 19.4961C33.2812 19.4961 31.582 18.9102 30.1172 18.4414C28.7109 18.0312 27.4805 17.6211 25.8398 17.6211C23.9062 17.6211 21.6211 18.1484 18.8672 19.2617L17.8125 19.7305V34.2031C20.5078 33.1484 23.0859 32.6211 25.5469 32.6211C28.0664 32.6211 30.1758 33.1484 32.0508 33.6172C33.8086 34.0273 35.3906 34.4375 37.207 34.4375C38.7305 34.4375 40.3711 34.1445 42.1875 33.5Z" fill="#2E3D45"/>
            </svg>
          </div>
          <div class="bgi" :class="[{missing:!useMarkersStore().getMarkerImage(props.item.value.properties.id,'m')}]" v-if="props.item.value.properties.type == 'poi' || props.item.value.properties.type == 'path'" :style="{ backgroundImage: 'url(' + returnImage(props.item.value.properties.id) + ')' }"></div>
          <div class="bgi" :class="[{missing:!useMarkersStore().getMarkerImage(props.item.value.properties.id,'m')}]" v-if="props.item.value.properties.type == 'event'" :style="{ backgroundImage: 'url(' + returnImage(props.item.value.properties.id) + ')' }"></div>
          <div class="content">
            <div class="categories">
              <img v-if="props.item.value.properties.type == 'event'" class="event-category" :src="timeIcon" alt="">
              <template  v-for="(category, index) in props.item.value.properties.categories">
                <img v-if="index != 0" :class="'style-' + props.item.value.properties.categories[index]"
                  :src="returncategoryIconTemplate(category)" alt=""
                  v-memo="[category]"
                  >
              </template>
            </div>
            <div class="address">{{ props.item.value.properties.address }}</div>
            <h1 class="title">{{ t(props.item.value.properties.id + '.title') }}</h1>
          </div>
        </div>
</template>
<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n'
import {useMarkersStore,useFavoritesStore,useMapStoreData,useCategoriesStore} from '@/stores/index'
import missingImageCulture from '@/assets/card/image-missing-culture.svg'
import missingImageNature from '@/assets/card/image-missing-nature.svg'
import missingImageTourism from '@/assets/card/image-missing-tourism.svg'
import missingImageSport from '@/assets/card/image-missing-sport.svg'
import missingImageEvent from '@/assets/card/image-missing-event.svg'
import timeIcon from '@/assets/time-icon.svg'

const { t } = useI18n();
const props = defineProps({
  item: Object,
});

const markers = useMapStoreData().markers;
function returncategoryIconTemplate(id) {
  let url = useCategoriesStore().returnCategoryIcon(id);
  return url;
}

function returnImage(id) {
  const images = useMarkersStore().getMarkerImage(id,'m');
  let image = undefined;

  image = images[0];
  if (!image && markers[id] != undefined) {
    let category = markers[id].properties.categories[0];
    switch (category) {
      case '80':
        image = missingImageNature;
        break;
      case '81':
        image = missingImageSport;
        break;
      case '82':
        image = missingImageCulture;
        break;
      case '83':
        image = missingImageTourism;
        break;
      default:
        image = missingImageCulture;
        break;
    }
  }else if(image == undefined || markers[id] == undefined) {
    
    if(markers.value[id] == undefined) {
      console.log("markers is undefined, id: ", id);
    }else if(image == undefined) {
      console.log("image is undefined, id: ", markers[id].properties);
    }else {
      console.log("something else is wrong",id);
    }
  }
  return image;
}

const toggleFavorites = (id) => {
  useFavoritesStore().toggleFavorites(id);
}
</script>
<style scoped lang="scss">
@import "../assets/style/mixins.scss";
@import "../assets/style/components/scenariosCard.scss";
</style>