<template>
  <div class="favorites" :class="[{ show: useFavoritesStore().isOpenPanel }]">
    <div :class="[{generatedPath:showQrCode}]" class="favorites-outside" data-cy="favorite-outside">
      <div class="holder" @click="togglePanelFavorites()">
        <div class="icon closed-icon">
          <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4433 19.9497C15.6944 20.5342 16.0113 21.0977 16.394 21.6294L1.66962 21.6294C-0.55654 18.5364 -0.55654 14.364 1.66962 11.2711C3.51311 8.70975 3.83 5.40823 2.62028 2.59244C2.36917 2.00795 2.05229 1.44437 1.66962 0.912704L16.394 0.912704C18.6201 4.00569 18.6201 8.17807 16.394 11.2711C14.5505 13.8324 14.2336 17.1339 15.4433 19.9497ZM13.6433 19.9497C12.5201 16.754 12.9833 13.1368 15.0331 10.2889C16.6746 8.00832 16.8225 5.00356 15.4769 2.59244L4.42023 2.59244C5.5435 5.78807 5.08024 9.40526 3.03047 12.2532C1.38901 14.5338 1.2411 17.5386 2.58673 19.9497L13.6433 19.9497ZM30 25.6953L1.67836 25.6953L1.67836 23.8362L30 23.8362L30 25.6953Z" fill="#2E3D45"/>
          </svg>
        </div>
        <div class="icon open-icon">
          <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.1524 0.910156L26.4649 10.7539C26.7578 11.0469 26.875 11.3984 26.875 11.75C26.875 12.1602 26.7578 12.5117 26.4649 12.8047L16.1524 22.6484C15.5664 23.1758 14.6875 23.1758 14.1602 22.5898C13.6328 22.0625 13.6328 21.125 14.2188 20.5977L22.0117 13.1562L2.08986 13.1562C1.26955 13.1562 0.683609 12.5703 0.683609 11.8086C0.683608 11.1055 1.26955 10.3437 2.08986 10.3437L22.0117 10.3437L14.2188 2.96094C13.6328 2.43359 13.6328 1.49609 14.1602 0.968749C14.6875 0.382812 15.5664 0.382812 16.1524 0.910156Z" fill="#2E3D45"/>
          </svg>
        </div>
        <div>{{ t('favorites_menu.outsidetext') }} <span v-if="useFavoritesStore().favorites.length > 0">+ {{ useFavoritesStore().favorites.length }}</span></div>
      </div>
      <div v-show="!mapStore.showMap" class="map-favorites-container">
        <svg v-show="useMapStoreData().hideMapFromHome" @click="showMap()" class="map-favorites" width="60" height="76" viewBox="0 0 60 76" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect y="75.6953" width="75" height="60" rx="30" transform="rotate(-90 0 75.6953)" fill="#2E3D45" />
          <path
            d="M17.418 21.9648C17.6523 21.5547 18.1211 21.3203 18.5312 21.3203L38.2187 21.3203C38.8047 21.3203 39.332 21.7305 39.5664 22.2578L43.3164 32.1016C43.4336 32.3945 43.4336 32.7461 43.3164 33.0391L39.7422 43.8203L43.3164 53.1953C43.4922 53.6055 43.4336 54.1328 43.1406 54.4844C42.9062 54.8945 42.4375 55.0703 41.9687 55.0703L22.2812 55.0703C21.6953 55.0703 21.2266 54.7187 20.9922 54.1914L17.2422 44.3477C17.125 44.0547 17.125 43.7031 17.2422 43.4102L20.8164 32.6289L17.2422 23.2539C17.0664 22.8437 17.125 22.3164 17.418 21.9648ZM39.9766 52.2578L37.2812 45.2266L20.582 45.2266L23.2773 52.2578L39.9766 52.2578ZM23.3359 33.9766L20.5234 42.4141L37.2227 42.4141L40.0352 33.9766L23.3359 33.9766ZM39.9766 31.1641L37.2812 24.1328L20.582 24.1328L23.2773 31.1641L39.9766 31.1641Z"
            fill="white" />
        </svg>
      </div>
      <div @click="useFavoritesStore().deleteAll()" v-if="showQrCode" :class="{map:pathRoute}" class="delete-favorites-path" data-cy="delete-favorites-path">
        <svg class="trash-icon" width="60" height="75" viewBox="0 0 60 75" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="75" width="75" height="60" rx="30" transform="rotate(-90 0 75)" fill="#2E3D45"/>
          <path d="M41.7188 27.4375C42.4805 27.4375 43.125 28.082 43.125 28.8438C43.125 29.6641 42.4805 30.25 41.7188 30.25H41.0156L39.6094 49.293C39.4336 51.2852 37.8516 52.75 35.8594 52.75H24.082C22.0898 52.75 20.5078 51.2852 20.332 49.293L18.9258 30.25H18.2812C17.4609 30.25 16.875 29.6641 16.875 28.8438C16.875 28.082 17.4609 27.4375 18.2812 27.4375H22.3242L24.4922 24.2148C25.0781 23.3359 26.1328 22.75 27.2461 22.75H32.6953C33.8086 22.75 34.8633 23.3359 35.4492 24.2148L37.6172 27.4375H41.7188ZM27.2461 25.5625C27.0703 25.5625 26.8945 25.6797 26.8359 25.7969L25.7227 27.4375H34.2188L33.1055 25.7969C33.0469 25.6797 32.8711 25.5625 32.6953 25.5625H27.2461ZM38.2031 30.25H21.7383L23.1445 49.1172C23.2031 49.5859 23.6133 49.9375 24.082 49.9375H35.8594C36.3281 49.9375 36.7383 49.5859 36.7969 49.1172L38.2031 30.25Z" fill="white"/>
        </svg>
      </div>
    </div>

    <div data-cy="favorites-full-list" class="favorites-full-list" :class="[{generatedPath:showQrCode,favoritesChanged:favoritesChanged}]">
      <div class="holder" v-show="useFavoritesStore().favorites.length > 0">
        <div class="qr-code" v-show="!calculatingPath">
          <p v-show="showQrCode" class="qr-code-paragraph">{{ t('favorites_menu.transfer_paths_text') }}</p>
          <div v-show="showQrCode" v-if="favoritesPoi.length > 0 && favoritesChanged" @click="createFavoritedPath()" class="btn-update-path">
            <img :src="updateIconBtn" alt=""><span>{{ t("favorites_menu.updatePath") }}</span>
          </div>
          <div v-show="showQrCode" data-cy="qr-wrapper" class="qr-wrapper">
            <QrCodeGenerator :text="urlToEncode" />
          </div>

          <div v-if="showPrint" @click="printMap()" class="btn-printing">
            <span>{{ t("favorites_menu.print") }}</span><img :src="printIcon" alt="">
          </div>
        </div>
        <div v-show="useMapStoreData().isOnline">
          <div class="btn-create-path" v-show="!showQrCode && !calculatingPath" @click="createFavoritedPath()">
              <PathIcon /><span v-if="!showQrCode">{{ t("favorites_menu.create_path") }}</span>
          </div>
        </div>
        <div v-if="fetchError">
          <h3>Error</h3>
        </div>
        <div v-if="calculatingPath" class="loading">
          <div class="container">
            <flagLoader></flagLoader>
          </div>
        </div>
        <div class="scroller">
          <div class="poi starting-point">
            <img :src="currentLocation" alt="">
            <div class="content">
              <div class="categories">
              </div>
              <p class="address">{{ t('general.you_are_here') }}</p>
              <h3>{{ t('general.starting_position') }}</h3>
            </div>
          </div>
          <div v-for="favorite in favoritesPoi" class="poi" :class="['style-' + favorite.categories[0]]">
            <!--<FlagFavoritesIcon @click="removeFavorited(favorite.id)" class="favorite-flag" />-->
            <div @click="removeFavorited(favorite.id)" class="favorite-flag" :class="[{event:favorite.type == 'event'}]">
              <img :src="closeIcon" alt="">
            </div>
            <div class="bgi" :class="[{missing:!useMarkersStore().getMarkerImage(favorite.id,'m')},{event:favorite.type == 'event'}]" :style="{ 'background-image': 'url(' + returnImage(favorite.id) + ')' }"></div>
            <div class="content">
              <div class="categories">
                <img class="event-category" :src="timeIcon" v-if="favorite.type == 'event'"> 
                <template v-for="(category, index) in favorite.categories">
                  <img v-if="index != 0" :class="'style-' + category" :src="useCategoriesStore().returnCategoryIcon(category)" alt="">
                </template>
              </div>
              <p class="address">{{ favorite.address }}</p>
              <h3>{{ t(favorite.id + '.title') }}</h3>
            </div>
          </div> 
        </div>
      </div>
      <div v-show="useFavoritesStore().favorites.length == 0" class="favorites-instruction" data-cy="favorites-instruction">
        <div class="bgi" :style="{backgroundImage:`url(${bgiEmpty})`}">
          <div class="panel">
            <p>{{ t('favorites_menu.instructions') }}</p>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { ref, computed,watchEffect } from "vue";
import { useRouter } from 'vue-router'
import { useMapStoreData, useFavoritesStore, useCategoriesStore, useMarkersStore, useSetMap,useScreensaverStore,usePrintingStore,useAnalyticsStore } from "@/stores/index";
import QrCodeGenerator from '@/components/QrCodeGenerator.vue'
import { useI18n } from "vue-i18n";
import PathIcon from '@/components/icons/PathIcon.vue'
import mapboxgl from 'mapbox-gl';
import html2pdf from 'html2pdf.js'
import flagLoader from '@/components/FlagLoader.vue'

//images
import missingImageCulture from '@/assets/card/image-missing-culture.svg'
import missingImageNature from '@/assets/card/image-missing-nature.svg'
import missingImageTourism from '@/assets/card/image-missing-tourism.svg'
import missingImageSport from '@/assets/card/image-missing-sport.svg'
import missingImageEvent from '@/assets/card/image-missing-event.svg'
import bgiEmpty from '@/assets/favorites/bgi.svg'
import currentLocation from '@/assets/current-location-icon.svg'
import closeIcon from '@/assets/single/close-icon.svg'
import updateIconBtn from '@/assets/favorites/update-icon-btn.svg'
import printIcon from "@/assets/favorites/printIcon.svg";
import loader from '@/assets/LOAD2.png';
import timeIcon from '@/assets/time-icon.svg'
import * as togeojson from "togeojson";

const route = useRoute();
const pathRoute = computed(() => route.path !== '/scenarios/');
const { t } = useI18n();
const markers = ref(useMapStoreData().markers);
const isOpen = ref(false);
const mapStore = useMapStoreData();
const apiMapBox = 'https://api.mapbox.com/directions/v5/mapbox/';
const apiToken = 'pk.eyJ1IjoiYXI5aXQiLCJhIjoiY2tzMDIzdDZoMWZ2bDJxczduMDJrcXVnYyJ9.cRFgHrdHVNT2-euD0Lgn0Q';
let urlToEncode = ref('');
const showQrCode = ref(false);
const showPrint = ref(false);
const calculatingPath = ref(false);
const router = useRouter();
const isEvent = ref(false);
const fetchError = ref(false);

const favoritesChanged = ref(true);
const ITINERARY_API_URL = import.meta.env.VITE_ITINERARY_API_URL
const PWA_APP_URL = import.meta.env.VITE_PWA_APP_URL

const favoritesPoi = computed(() => {
  fetchError.value = false;
  if (useFavoritesStore().favorites.length == 0) {
    showQrCode.value = false;
    favoritesChanged.value = false;
    urlToEncode.value = '';
  } else {
    favoritesChanged.value = true;
  }
  return useFavoritesStore().favorites.map(id => {
    return useMapStoreData().markers[id].properties;
  });
});

const showMap = () => {
  fetchError.value = false;
  useMapStoreData().hideMap = false;
  useMapStoreData().showMap = true;
  router.push('/');
}

const removeFavorited = (id) => {
  favoritesChanged.value = true;
  useFavoritesStore().toggleFavorites(id);
}

const togglePanelFavorites = () => {
  useFavoritesStore().isOpenPanel = !useFavoritesStore().isOpenPanel;
}

const createFavoritedPath = async () => {
  
  if (favoritesChanged.value == false) {
    return;
  }

  let pathType = useMapStoreData().options.directionsType;
  if(['driving', 'walking', 'cycling'].indexOf(pathType) == -1)  pathType = 'walking';
  const { device_location_lat, device_location_lng } = useMapStoreData().options;

  //console.log("useMapStoreData().options:",useMapStoreData().options); 
  let pois = [];

  let isValidPath = false;
  let bounds;
 if(useFavoritesStore().favorites.length == 1 && markers.value[useFavoritesStore().favorites[0]].properties.type == 'path'){ // use path geometry
  pois.push( markers.value[useFavoritesStore().favorites[0]]);
  isValidPath = true;
  const { path_geometry, files } = markers.value[useFavoritesStore().favorites[0]].properties;

  let pathRoute;
  if (path_geometry) {
        /*data = path_geometry;
        console.log("marker.id",marker);*/
        pathRoute = {
                  type: 'Feature',
                  properties: {},
                  geometry: path_geometry,
              }

    } else if (files?.gpx_path?.file) {
        const file = markers.value[useFavoritesStore().favorites[0]].properties.files.gpx_path.file;
        const path = `${mapStore.publicUrl}repo/${mapStore.destinationId}/${file.hash}.${file.ext}`;
        //console.log('path', path);
        // Fetch the gpx file and convert it to geojson
        const response = await fetch(path);
        const str = await response.text();
        const doc = new DOMParser().parseFromString(str, "text/xml");
        pathRoute = togeojson.gpx(doc).features[0];
        //data = fulldata.features[0].geometry;
    }
    else {
      isValidPath = false;
    }
    if(isValidPath){




      pois = [];

     const coordinates = [`${device_location_lng},${device_location_lat}`, ...useFavoritesStore().favorites
        .map(id => {
        pois.push(markers.value[id]);
        return markers.value[id].geometry.coordinates.join(',');
      })].join(';');

      const url =  `${apiMapBox}${pathType}/${encodeURIComponent(coordinates)}?alternatives=false&geometries=geojson&steps=false&access_token=${apiToken}`;
  

    try {
      const response = await fetch(url, { method: 'GET' });

      if (!response.ok) {
      throw new Error(`Network response was not ok ${response.statusText}`);
      }

    const textResponse = await response.text();
    //console.log(textResponse.trim());
    let pathsource = JSON.parse(textResponse);
    //dodamo predefinirano pot
    pathsource.routes[0].geometry.coordinates.push(...pathRoute.geometry.coordinates);


    let layoutName = 'path_' + pathsource.routes[0].distance;
    useSetMap().createPath(layoutName, pathsource.routes[0].geometry,'markerPath');


      // Create a 'LngLatBounds' with both corners at the first coordinate.
      bounds =  new mapboxgl.LngLatBounds(
        [device_location_lng, device_location_lat],
        [device_location_lng, device_location_lat]  
      );
      
      for (const coords of pathsource.routes[0].geometry.coordinates) {
        bounds.extend(coords);
      }
      if(usePrintingStore().printMap != null) { 
        usePrintingStore().printMap.fitBounds(bounds, {
              padding:300,
              pitch: 0,
              duration:0,
              animate: false,
          });
      }

      useSetMap().map.fitBounds(bounds, {
          padding:300,
          pitch: 0,
          duration:100
      });
    await saveItinerary(pathType, pathsource,pois);
  } catch (error) {
     isValidPath = false;
     console.error('There has been a problem with your fetch operation:', error);
  }


   



    }


 }
 if(!isValidPath){ // calculate path
  //najprej optimiziramo vrstbi red
  if(useFavoritesStore().favorites.length > 1){
    calculatingPath.value = true;
    try{
      await useFavoritesStore().orderFavorites(ITINERARY_API_URL, [device_location_lng,device_location_lat]);
    
    }catch(e){
      console.log('napak pri optimizaciji poti')
    }
    calculatingPath.value = false;
  }

  pois = [];
  isValidPath = true;
  //await useFavoritesStore().orderFavorites();
  const coordinates = [`${device_location_lng},${device_location_lat}`, ...useFavoritesStore().favorites
    .map(id => {
      pois.push(markers.value[id]);
      return markers.value[id].geometry.coordinates.join(',');
    })].join(';');

      // Create a 'LngLatBounds' with both corners at the first coordinate.
       bounds =  new mapboxgl.LngLatBounds(
        [device_location_lng, device_location_lat],
        [device_location_lng, device_location_lat]  
      );
      
      for (const poi of pois) {
        bounds.extend(poi.geometry.coordinates);
      }
      
      if(usePrintingStore().printMap != null) { 
        usePrintingStore().printMap.fitBounds(bounds, {
              padding:300,
              pitch: 0,
              duration:0,
              animate: false,
          });
      }

      useSetMap().map.fitBounds(bounds, {
          padding:300,
          pitch: 0,
          duration:100
      });

  const url =  `${apiMapBox}${pathType}/${encodeURIComponent(coordinates)}?alternatives=false&geometries=geojson&steps=false&access_token=${apiToken}`;
  

  try {
    const response = await fetch(url, { method: 'GET' });

    if (!response.ok) {
      throw new Error(`Network response was not ok ${response.statusText}`);
    }

    const textResponse = await response.text();
    //console.log(textResponse.trim());
    let pathsource = JSON.parse(textResponse);
    let layoutName = 'path_' + pathsource.routes[0].distance;
    useSetMap().createPath(layoutName, pathsource.routes[0].geometry,'favoritedPath');
    if(useMapStoreData().destinationId == '1') {
      usePrintingStore().setUpTemplate();
      showPrint.value = true;
      //usePrintingStore().addPath(layoutName, pathsource.routes[0].geometry);
    }
    await saveItinerary(pathType, pathsource,pois);
  } catch (error) {
    isValidPath = false;
    console.error('There has been a problem with your fetch operation:', error);
  }
  
}
};


function getBounds(coordinates){

  let bounds = new mapboxgl.LngLatBounds(
        [coordinates[0][0], coordinates[0][1]],
        [coordinates[0][0], coordinates[0][1]] 
      );
    for (let i = 1; i < coordinates.length; i++) {
        bounds.extend(coordinates[i]);
    }
    return bounds;    

}

function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

const formatRequest = (backinterface, method, parameters) => {
  var request = new Object();
  var obj = new Object();
  request.interface = backinterface;
  request.method = method;
  request.parameters = parameters;

  obj.request = request;

  //if (this.token.length > 0) obj.token = this.token;
  return JSON.stringify(obj);
}



async function saveItinerary(pathType, pathsource,pois) {
  let hashInt = hashCode(useFavoritesStore().favorites.join('-'));
  let hashString = parseInt(hashInt);
  let nameItinerary = pathType + '-' + hashString;
  var myOptions = {
    destination: useMapStoreData().destinationId,
    repoUrl: useMapStoreData.publicUrl + 'repo/' + useMapStoreData().destinationId + '/'
  }

  let headers = {
    'Content-Type': 'application/json'
  };
  try {
  let jsonRequest = formatRequest('LocationRequests', 'saveItinerary', { name: nameItinerary, pois: pois, route: pathsource.routes[0], options: myOptions })
  const saveResponse = await fetch(ITINERARY_API_URL, {
    method: "POST",
    headers: headers,
    body: jsonRequest,
    credentials: 'omit' // 'omit' is equivalent to xhrFields: {withCredentials: false}
  })

  if (!saveResponse.ok) {
    throw new Error(`Save itinerary network response was not ok ${saveResponse.statusText}`);
  }
    // Handle the response from saving itinerary
    const saveResponseText = await saveResponse.text();
  urlToEncode.value = PWA_APP_URL + '?' + nameItinerary;
  showQrCode.value = true;
  favoritesChanged.value = false;
  fetchError.value = false;
} catch (error) {
  fetchError.value = true;
  console.error('Error:', error);
}

}

function returnImage(id) {

const images = useMarkersStore().getMarkerImage(id, 'm');
let image = undefined;

image = images[0];

if (!image && markers.value[id] != undefined) {
  let category = markers.value[id].properties.categories[0];

  switch (category) {
    case 80:
      image = missingImageNature;
      break;
    case 81:
      image = missingImageSport;
      break;
    case 82:
      image = missingImageCulture;
      break;
    case 83:
      image = missingImageTourism;
      break;
    default:
      image = missingImageCulture;
      break;
  }
} else if (image == undefined || markers.value[id] == undefined) {

  if (markers.value[id] == undefined) {
    console.log("markers is undefined, id: ", id);
  } else if (image == undefined) {
    console.log("image is undefined, id: ", markers.value[id].properties);
  } else {
    console.log("something else is wrong", id);
  }
}

if(markers.value[id].properties.type == 'event') {
  if(image == undefined) {
    image = missingImageEvent;
  }
  isEvent.value = true;
}else {
  isEvent.value = false;
}

return image;
}

function printMap() {
  showPrint.value = false;
  let printElement = document.getElementById('printing-adventure-template');
        var opt = {
            margin:       0,
            filename:     'myfile.pdf',
            jsPDF:        { unit: 'in', format: 'A4', orientation: 'landscape' }
        };

    html2pdf().set(opt).from(printElement).toPdf().output('datauristring').then(function (pdf) {
        let pdfSplit = pdf.split("data:application/pdf;filename=generated.pdf;base64,");
        let pdfBase64 = pdfSplit[1];
        useAnalyticsStore().sendToPrint(pdfBase64);
    });
}

watchEffect(() => {
  if(useScreensaverStore().showSreensaver) {
    isOpen.value = false;
  }
});
</script>
<style scoped lang="scss">
@import "../assets/style/mixins.scss";
@import "../assets/style/components/favorites.scss";
</style>