<template>
<div class="languageChanger" v-if="allLanguages.length != 1" :class="[uniqueClass,{'isOpen': isOpen}]">
  <div class="option" @click="toggleDropdown">
        <img :src="flags[selectedLanguage]" alt="">
        <div class="line"></div>
      <span>{{ selectedLanguage ? selectedLanguage : 'Select an option' }}</span>
    </div>
  <ul class="list" v-show="isOpen">
      <li class="option" v-for="option in allLanguages" v-show="selectedLanguage != option" :key="option" @click="selectOption(option)">
        <img :src="flags[option]" alt="">
        <div class="line"></div>
        <span>{{option}}</span>
      </li>
    </ul>

  </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import {useMapStoreData,useScreensaverStore} from '@/stores/index'
import { useI18n } from 'vue-i18n'
import enFlag from '@/assets/languages/en-flag.svg'; 
import slFlag from '@/assets/languages/sl-flag.svg';
import deFlag from '@/assets/languages/de-flag.svg';
import hrFlag from '@/assets/languages/hr-flag.svg';
import itFlag from '@/assets/languages/it-flag.svg';

const { locale } = useI18n();
const flags = {
  en: enFlag,
  sl: slFlag,
  de: deFlag,
  hr: hrFlag,
  it: itFlag,
}

const mapStore = useMapStoreData();

const allLanguages = ref(mapStore.allLanguages);
const isOpen = ref(false);
const screensaverStore = useScreensaverStore();
const { uniqueClass } = defineProps(['uniqueClass']);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const selectOption = (option) => {
  selectedLanguage.value = option;
  mapStore.changeLanguage(option);
  locale.value = option;
  screensaverStore.stopScreensaverTimer();
  toggleDropdown();
};

const selectedLanguage = computed(() => mapStore.currentLanguage);

</script>
<style scoped lang="scss">
@import '../assets/style/mixins.scss';
@import '../assets/style/components/languageChanger.scss';
</style>
